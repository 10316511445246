import React, { useState, useContext, useEffect } from "react";
import { collection, addDoc, query, where, onSnapshot, doc, deleteDoc } from "firebase/firestore";
import { db } from "./Firebase/firebaseConfig"; // Update the import path as needed
import UserContext from "../components/UserContext";
import { Button, Textarea, Link } from "@nextui-org/react"; // Update the import path as needed
import { Send } from "iconsax-react";
import { formatDistanceToNow } from 'date-fns';
import { addCommentNotification, addReplyNotification } from "./Firebase/addLikeNotification"; // Update the import path as needed
import { checkProfanity } from './PerspectiveService'; // Import the service
import { useTranslation } from 'react-i18next';


const formatTime = (date) => {
    let result = formatDistanceToNow(date, { addSuffix: false });
    result = result.replace(/about (\d+) hours?/, '$1 h');
    result = result.replace(/(\d+) minutes?/, '$1 min');
    return result;
};

const ImageWithComments = ({ imageId, imageOwnerId, inputRef }) => {
    const { currentUser } = useContext(UserContext);
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);
    const [replies, setReplies] = useState([]);
    const [reply, setReply] = useState({});
    const [showReply, setShowReply] = useState(null);
    const [showAllComments, setShowAllComments] = useState(false);
    const { t } = useTranslation();


    useEffect(() => {
        if (!imageId) return; // Early return if imageId is undefined

        const q = query(collection(db, "comments"), where("imageId", "==", imageId));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const commentsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            commentsList.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
            setComments(commentsList);
        });

        return () => {
            unsubscribe();
        };
    }, [imageId]);

    useEffect(() => {
        if (comments.length === 0) return;

        const qReplies = query(collection(db, "replies"));
        const unsubscribeReplies = onSnapshot(qReplies, (snapshot) => {
            const repliesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setReplies(repliesList);
        });

        return () => {
            unsubscribeReplies();
        };
    }, [comments]);

    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        if (!currentUser) {
            alert("Please log in to comment.");
            return;
        }
        if (comment.trim() === "") {
            alert("Comment cannot be empty.");
            return;
        }
        const isProfane = await checkProfanity(comment);
        if (isProfane) {
            alert("Your comment contains inappropriate language.");
            return;
        }
        const commentDoc = await addDoc(collection(db, "comments"), {
            userId: currentUser.uid,
            imageId,
            comment,
            userName: currentUser.username || currentUser.email,
            createdAt: new Date()
        });
        setComment("");

        // Add comment notification
        await addCommentNotification(currentUser.username || currentUser.email, currentUser.uid, imageId, imageOwnerId, comment);
    };

    const handleReplySubmit = async (commentId, replyText, commentOwnerId) => {
        if (!currentUser) {
            alert("Please log in to reply.");
            return;
        }
        if (!replyText || replyText.trim() === "") {
            alert("Reply cannot be empty.");
            return;
        }
        const isProfane = await checkProfanity(replyText);
        if (isProfane) {
            alert("Your reply contains inappropriate language.");
            return;
        }
        const replyDoc = await addDoc(collection(db, "replies"), {
            userId: currentUser.uid,
            commentId,
            reply: replyText,
            userName: currentUser.username || currentUser.email,
            createdAt: new Date()
        });
        setReply({ ...reply, [commentId]: "" });
        setShowReply(null);

        // Add reply notification
        await addReplyNotification(currentUser.username || currentUser.email, currentUser.uid, imageId, commentId, commentOwnerId, replyText);
    };

    const handleDelete = async (commentId) => {
        const commentRef = doc(db, "comments", commentId);
        await deleteDoc(commentRef);
    };

    return (
        <div>
            <div>
                <div>
                    <form className="flex items-stretch h-[40px]" onSubmit={handleCommentSubmit}>
                        <Textarea
                            className="flex-grow mr-2 h-[40px]"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder={t("writecomment")}
                            variant="underlined"
                            size="sm"
                            ref={inputRef}
                        />
                        <Button isIconOnly size="sm" className="flex-shrink-0 h-auto m-0" variant="Light" color="primary" type="submit">
                            <Send size="18" variant="Bold" className="svg-primary-200" />
                        </Button>
                    </form>
                </div>
                <div>
                    {(showAllComments ? comments : comments.slice(0, 1)).map((c) => (
                        <div key={c.id} className="mt-5">
                            <div className="flex flex-row items-center gap-2">
                                <Link to={`/profile/${c.userName || "Unknown"}`} className="text-sm cursor-pointer hover:underline text-default-700 font-medium">
                                    {c.userName || "Unknown"}
                                </Link>
                                <p>{c.comment}</p>
                            </div>
                            <div className="flex items-center gap-2">
                                <p className="text-xs text-default-500">{formatTime(new Date(c.createdAt.seconds * 1000))}</p>
                                {currentUser && currentUser.uid === c.userId && (
                                    <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => handleDelete(c.id)}>
                                        Delete
                                    </Link>
                                )}
                                <Link className="text-sm text-default-500 hover:underline cursor-pointer" size="sm" variant="light" onClick={() => setShowReply(c.id === showReply ? null : c.id)}>
                                    Reply
                                </Link>
                            </div>
                            {c.id === showReply && (
                                <div className="flex mb-2">
                                    <Textarea
                                        variant="underlined"
                                        className="flex-grow mr-2"
                                        value={reply[c.id] || ""}
                                        onChange={(e) => setReply({ ...reply, [c.id]: e.target.value })}
                                        placeholder="Write a reply..."
                                        minRows={1}
                                    />
                                    <Button
                                        className="flex-shrink-0 h-auto"
                                        color="default"
                                        variant="light"
                                        isIconOnly
                                        onClick={() => handleReplySubmit(c.id, reply[c.id], c.userId)}
                                    >
                                        <Send size="18" variant="Bold" />
                                    </Button>
                                </div>
                            )}
                            {replies
                                .filter(r => r.commentId === c.id)
                                .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
                                .map((r) => (
                                    <div className="ps-4 mt-2" key={r.id}>
                                        <div className="flex flex-row items-center gap-2">
                                            <Link to={`/profile/${r.userName || "Unknown"}`} className="text-sm cursor-pointer hover:underline text-default-700 font-medium">
                                                {r.userName || "Unknown"}
                                            </Link>
                                            <p>{r.reply}</p>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <p className="text-xs text-default-500">{formatTime(new Date(r.createdAt.seconds * 1000))}</p>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    ))}
                </div>
                {!showAllComments && comments.length > 2 && (
                    <div className="mt-2">
                        <Link className="text-sm text-default-500 hover:underline cursor-pointer" onClick={() => setShowAllComments(true)}>
                            Show all comments
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageWithComments;
