// DescriptionObject.js
import React, { useState, useEffect, useContext } from 'react';
import { doc, collection, getDoc } from 'firebase/firestore';
import { db } from './Firebase/firebaseConfig';
import UserContext from './UserContext'; // Adjust the path as necessary
import DescriptionObjectCard from './DescriptionObjectCard';


const DescriptionObject = ({ firstResult }) => {
    const { currentUser } = useContext(UserContext);
    const userId = currentUser?.uid;

    // State variables to manage the component's state
    const [tagsParam, setTagsParam] = useState(''); // State to store the tags parameter
    const [isInTarget, setIsInTarget] = useState(false);
    const [isCelestialObject, setIsCelestialObject] = useState(false); // State to store if it is a celestial object

    // Effect to handle URL changes and update tagsParam
    useEffect(() => {
        const handleURLChange = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const tags = urlParams.get('tags');
            if (tags) {
                setTagsParam(tags);
                checkIfCelestialObject(tags); // Check if the tags parameter indicates a celestial object
            }
        };

        // Listen for changes to the URL
        window.addEventListener('popstate', handleURLChange);
        handleURLChange(); // Initial check

        return () => {
            window.removeEventListener('popstate', handleURLChange);
        };
    }, []);

    // Function to check if the tags parameter contains celestial object keywords
    const checkIfCelestialObject = (tags) => {
        const celestialKeywords = [
            "planet", "Mercury", "Venus", "Mars", "Jupiter", "Saturn", "Uranus", "Neptune", "Sun",
            "NGC", "IC"
        ];

        const isCelestial = celestialKeywords.some(keyword => {
            if (keyword === "NGC" || "IC") {
                return tags.includes(keyword);
            }
            return tags.toLowerCase().includes(keyword.toLowerCase());
        });

        setIsCelestialObject(isCelestial);
    };

    // Effect to check if the celestial object is in the user's targets
    useEffect(() => {
        const checkIfInTarget = async () => {
            if (!userId) return;

            try {
                const userDocRef = doc(db, 'users', userId);
                const cosmioDocRef = doc(collection(userDocRef, 'cosmio'), 'targets');

                const targetDoc = await getDoc(cosmioDocRef);
                const targetData = targetDoc.exists() ? targetDoc.data() : {};

                const targets = targetData.targets || [];

                setIsInTarget(targets.includes(tagsParam));
            } catch (error) {
                console.error('Error checking target:', error);
            }
        };

        if (tagsParam) {
            checkIfInTarget();
        }
    }, [tagsParam, userId]);

    // Render logic
    if (!isCelestialObject) {
        return null;
    }

    return (
        <DescriptionObjectCard
            imageUrl={firstResult?.imageUrl}
            tagsParam={tagsParam}
            userId={userId}
            isInTarget={isInTarget}
            onTargetToggle={(tag, added) => setIsInTarget(added)}
        />
    );
};

export default DescriptionObject;
