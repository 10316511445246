import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { collection, doc, updateDoc, addDoc, deleteDoc, query, where, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../components/Firebase/firebaseConfig";
import UserContext from "../components/UserContext"; // Assurez-vous que le chemin est correct
import { Heart } from "iconsax-react"; // Import de l'icône de cœur
import { useSpring, animated } from '@react-spring/web';
import { Button } from "@nextui-org/react";
import { addLikeNotification } from "../components/Firebase/addLikeNotification"; // Import correct
import { useAptabase } from '@aptabase/react';

function LikeButton({ likedBy, likedById, imageId, initialLikes, recipientId }) {
    const [likes, setLikes] = useState(initialLikes);
    const [liked, setLiked] = useState(false);
    const { currentUser } = useContext(UserContext);
    const heartRef = useRef(null);
    const { trackEvent } = useAptabase();

    const fetchUserLike = useCallback(async () => {
        if (!currentUser) return;

        const likesCollection = collection(db, "likes");
        const q = query(likesCollection, where("userId", "==", currentUser.uid), where("imageId", "==", imageId));
        const likesSnapshot = await getDocs(q);

        if (!likesSnapshot.empty) {
            setLiked(true);
        }
    }, [currentUser, imageId]);

    useEffect(() => {
        fetchUserLike();
    }, [fetchUserLike]);

    useEffect(() => {
        const imageRef = doc(db, "images", imageId);

        const unsubscribe = onSnapshot(imageRef, (doc) => {
            if (doc.exists()) {
                setLikes(doc.data().like);
            }
        });

        return () => unsubscribe();
    }, [imageId]);

    const handleLike = useCallback(async () => {


        if (!currentUser) {
            alert("Please log in to like images.");
            return;
        }

        const likesCollection = collection(db, "likes");
        const imageRef = doc(db, "images", imageId);
        let newLikes = likes;

        if (liked) {
            const q = query(likesCollection, where("userId", "==", currentUser.uid), where("imageId", "==", imageId));
            const likesSnapshot = await getDocs(q);

            if (!likesSnapshot.empty) {
                await deleteDoc(doc(db, "likes", likesSnapshot.docs[0].id));
                newLikes = likes - 1;
                setLiked(false);
            }
        } else {
            await addDoc(likesCollection, { userId: currentUser.uid, imageId });
            newLikes = likes + 1;
            setLiked(true);
            trackEvent('like', { id: imageId });
            // Ajouter une notification
            if (recipientId) {
                await addLikeNotification(likedBy, likedById, imageId, recipientId);
            } else {
                console.error("recipientId is undefined");
            }
        }

        await updateDoc(imageRef, { like: newLikes });
    }, [currentUser, liked, likes, imageId, likedBy, likedById, recipientId]);

    const [springProps, setSpringProps] = useSpring(() => ({
        transform: 'scale(1)',
    }));

    const handleIconClick = () => {
        if (!currentUser) {
            alert("Please log in to like images.");
            return;
        }

        // Mise à jour locale immédiate pour l'animation
        setLiked(!liked);
        setLikes(liked ? likes - 1 : likes + 1);

        // Animation du coeur
        setSpringProps({ transform: 'scale(1.4)' });
        setTimeout(() => setSpringProps({ transform: 'scale(1)' }), 100);

        // Mise à jour asynchrone de la base de données
        handleLike();
    };

    return (

        <div className="flex flex-row gap-1 items-center p-1 cursor-pointer mr-1" onClick={handleIconClick} >
            <animated.div style={springProps}>
                <Heart
                    ref={heartRef}
                    variant={liked ? "Bold" : "Outline"}
                    size="24"
                    style={{ cursor: 'pointer', color: liked ? 'hsl(var(--nextui-danger-400))' : 'hsl(var(--nextui-default-700))' }}
                />
            </animated.div>
            <p className="font-bold" >{likes}</p>
        </div>



    );
}

export default LikeButton;
