import React, { useContext, useRef, useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAptabase } from '@aptabase/react';
import { Capacitor } from '@capacitor/core';

import { InAppReview } from '@capacitor-community/in-app-review';
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Upload from "./components/Upload";
import Profile from "./components/Profile";
import Search from "./components/Search";
import EditImageDetails from "./components/EditImageDetails";
import UserContext from "./components/UserContext";
import Signup from "./components/Signup";
import Login from "./components/Login";
import Footer from "./components/Footer/Footer";
import LegalNotice from "./components/Footer/LegalNotice";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy";
import TermsOfService from "./components/Footer/TermsOfService";
import EULA from "./components/Footer/EULA"
import VersionCheck from './components/VersionCheck';
import Ranking from './components/Ranking';
import { GlobalProvider } from './components/GlobalState';
import Cloud from './components/Cloud';
import TopNavBar from './components/TopNavBar';

import Contact from "./components/Footer/Contact";
import FAQ from "./components/Footer/FAQ";
import Admin from "./components/Admin";
import AlertSystem from "./components/AlertSystem"
import AssistantVirtuel from "./components/AssistantVirtuel";

function App() {
  const { currentUser } = useContext(UserContext);
  const [pageContent, setPageContent] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();
  const overflowDivRef = useRef(null);
  const { trackEvent } = useAptabase();

  const companyName = "Paul Joly";
  const companyAddress = "123 Astro Lane, Galaxy City, Universe";
  const companyPhone = "123-456-7890";
  const companyEmail = "contact@starmaze.com";
  const directorName = "Paul Joly";
  const hostingCompanyName = "Firebase Hosting (Google LLC)";
  const hostingCompanyAddress = "1600 Amphitheatre Parkway, Mountain View, CA 94043, USA";
  const thirdPartyServices = [
    { name: "Firebase Google", description: "Backend services provided by Google LLC." },
    { name: "Gemini AI", description: "AI services provided by Google LLC." },
    { name: "Astrometry Nova API", description: "Astronomical data service provided by Nova." },
    { name: "Aptabase", description: "Analytics service provided by Aptabase." }
  ];

  const handleSignup = () => {
    console.log("User signed up");
  };

  const handleLogin = () => {
    console.log("User logged in");
  };

  // Function to reset scroll position
  const resetScroll = () => {
    if (overflowDivRef.current) {
      overflowDivRef.current.scrollTop = 0;
    }
  };


  useEffect(() => {
    const handleScroll = () => {
      if (overflowDivRef.current) {
        sessionStorage.setItem('scrollPosition', overflowDivRef.current.scrollTop);
      }
    };

    if (overflowDivRef.current) {
      overflowDivRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (overflowDivRef.current) {
        overflowDivRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const unlisten = location.pathname; // Stocke uniquement le chemin sans les paramètres

    // Remettre le scroll à 0 à chaque changement de route sans paramètres
    if (overflowDivRef.current) {
      overflowDivRef.current.scrollTop = 0;
    }

    // Si vous avez besoin de désactiver un écouteur ou nettoyer des effets secondaires,
    // vous pouvez retourner une fonction de nettoyage ici.

  }, [location.pathname]); // Écoute seulement les changements du chemin, pas les paramètres


  useEffect(() => {
    trackEvent('navigate', { route: location.pathname });
  }, [location, trackEvent]);



  useEffect(() => {
    const firstLaunchDate = localStorage.getItem('firstLaunchDate');
    const now = Date.now();

    if (Capacitor.isNativePlatform()) {
      if (!firstLaunchDate) {
        // Premier lancement, stockez la date actuelle
        localStorage.setItem('firstLaunchDate', now.toString());
      } else {
        // Vérifiez si 3 semaines se sont écoulées depuis le premier lancement
        const threeWeeksInMs = 604800016; // 3 semaines en millisecondes
        const timeSinceFirstLaunch = now - parseInt(firstLaunchDate);

        if (timeSinceFirstLaunch >= threeWeeksInMs) {
          const hasRequestedReview = localStorage.getItem('hasRequestedReview');

          if (!hasRequestedReview) {
            InAppReview.requestReview(); // Demande de notation
            localStorage.setItem('hasRequestedReview', 'true');
          }
        }
      }
    }
  }, []);



  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <GlobalProvider>
      <VersionCheck />
      <AlertSystem />
      <div className="App" style={{ display: "flex", overflowY: 'scroll', height: '100vh' }}>


        <NavBar resetScroll={resetScroll} />
        <div className="w-full mx-auto overflow-auto main-content" ref={overflowDivRef}>
          <TopNavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={currentUser ? <Navigate to={`/profile/${currentUser.username}`} /> : <Profile />} />
            <Route path="/profile/:username" element={<Profile />} />
            <Route path="/storage" element={<Cloud />} />
            <Route path="/signup" element={<Signup onSignup={handleSignup} />} />
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/search" element={<Search />} />
            <Route path="/ranking" element={<Ranking />} />
            <Route path="/publish" element={<Upload />} />

            <Route path="/edit/:imageId" element={<EditImageDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/admin" element={<Admin />} />
            <Route
              path="/legal-notice"
              element={
                <LegalNotice
                  companyName={companyName}
                  companyAddress={companyAddress}
                  companyPhone={companyPhone}
                  companyEmail={companyEmail}
                  directorName={directorName}
                  hostingCompanyName={hostingCompanyName}
                  hostingCompanyAddress={hostingCompanyAddress}
                  thirdPartyServices={thirdPartyServices}
                  content={pageContent}
                />
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <PrivacyPolicy
                  companyName={companyName}
                  companyEmail={companyEmail}
                />
              }
            />
            <Route
              path="/terms-of-service"
              element={
                <TermsOfService
                  companyName={companyName}
                  companyEmail={companyEmail}
                />
              }
            />
            <Route
              path="/eula"
              element={
                <EULA
                  companyName={companyName}
                  companyEmail={companyEmail}
                />
              }
            />
          </Routes>
          <Footer />
        </div>
      </div>
    </GlobalProvider>
  );
}

export default App;
