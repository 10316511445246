import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './output.css';
import App from './App';
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';  // Import du détecteur de langue

import reportWebVitals from './reportWebVitals';
import { NextUIProvider } from '@nextui-org/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserContextProvider } from './components/UserContext';
import { SearchProvider } from './components/SearchContext';
import { AptabaseProvider } from '@aptabase/react';

import enTranslation from './locales/en/translation.json';
import frTranslation from './locales/fr/translation.json';
import deTranslation from './locales/de/translation.json';
import esTranslation from './locales/es/translation.json';
import ptTranslation from './locales/pt/translation.json';
import zhTranslation from './locales/zh/translation.json';
import itTranslation from './locales/it/translation.json';


i18n
  .use(LanguageDetector)  // Utilisation du plugin de détection de langue
  .use(initReactI18next)   // Intégration avec React
  .init({
    detection: {
      order: ['navigator'],  // Détecte la langue à partir des paramètres du navigateur
    },
    resources: {
      en: {
        translation: enTranslation
      },
      fr: {
        translation: frTranslation
      },
      de: {
        translation: deTranslation
      },
      es: {
        translation: esTranslation
      },
      pt: {
        translation: ptTranslation
      },
      zh: {
        translation: zhTranslation
      },
      it: {
        translation: itTranslation
      }
    },
    fallbackLng: 'en',  // Langue par défaut si la langue détectée n'est pas disponible
    interpolation: {
      escapeValue: false  // React s'occupe déjà de l'échappement des valeurs
    },
    debug: true,  // Mode debug activé pour afficher les informations dans la console
  });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}> {/* Correction ici */}
      <AptabaseProvider appKey="A-EU-2793968563">
        <Router>
          <NextUIProvider>
            <div className="purple-dark text-foreground bg-background">
              <UserContextProvider>
                <SearchProvider>
                  <App />
                </SearchProvider>
              </UserContextProvider>
            </div>
          </NextUIProvider>
        </Router>
      </AptabaseProvider>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();