import React from 'react';


const LegalNotice = ({ companyName, companyAddress, companyPhone, companyEmail, directorName, hostingCompanyName, hostingCompanyAddress, thirdPartyServices }) => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-4">Legal Notice</h1>
            <p className="mb-4">This site is published by:</p>
            <p className="mb-4">
            
                <strong>Email:</strong> {companyEmail} <br />
                <strong>Director of Publication:</strong> {directorName}
            </p>
            <p className="mb-4">This site is hosted by:</p>
            <p className="mb-4">
                <strong>Hosting Company Name:</strong> {hostingCompanyName} <br />
                <strong>Address:</strong> {hostingCompanyAddress}
            </p>
            {thirdPartyServices && (
                <div>
                    <h2 className="text-2xl font-bold mb-4">Third-Party Services</h2>
                    <p className="mb-4">This site uses the following third-party services:</p>
                    <ul className="mb-4">
                        {thirdPartyServices.map((service, index) => (
                            <li key={index} className="mb-2">
                                <strong>{service.name}</strong>: {service.description}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <h2 className="text-2xl font-bold mb-4">Intellectual Property</h2>
            <p className="mb-4">
                The entire content of this site, including domain name, trademarks, logo, text, etc., is the property of {companyName}, and is protected by the laws in force on intellectual property. No element of this site may be copied, reproduced, misappropriated, or distorted in any way, under any medium, without constituting an infringement within the meaning of articles L 335-2 and following of the intellectual property code.
            </p>
            <h2 className="text-2xl font-bold mb-4">Personal Data</h2>
            <p className="mb-4">
                In accordance with the Data Protection Act of January 6, 1978, and the General Data Protection Regulation (GDPR), you have the right to access, modify, rectify, and delete data concerning you. You can exercise this right by contacting {companyName} at {companyEmail}.
            </p>
            <h2 className="text-2xl font-bold mb-4">Liability</h2>
            <p className="mb-4">
                The information provided on this site is for general information purposes only. {companyName} makes every effort to provide accurate information, but does not guarantee the completeness, accuracy, or timeliness of the information provided on the site. {companyName} cannot be held responsible for any direct or indirect damage resulting from the use of this site.
            </p>
            <h2 className="text-2xl font-bold mb-4">Links to Other Websites</h2>
            <p className="mb-4">
                This site may contain links to other websites. {companyName} is not responsible for the content of these external sites.
            </p>
        </div>
    );
};

export default LegalNotice;
