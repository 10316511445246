import React from 'react';



const TermsOfService = ({ companyName, companyEmail }) => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>
            <p className="mb-4">Effective Date: June 29, 2024</p>

            <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
            <p className="mb-4">
                Welcome to {companyName}. These terms and conditions outline the rules and regulations for the use of our website and services.
            </p>

            <h2 className="text-2xl font-bold mb-4">2. Acceptance of Terms</h2>
            <p className="mb-4">
                By accessing and using our website, you accept and agree to be bound by these terms and conditions. If you do not agree with any part of the terms, you must not use our website.
            </p>

            <h2 className="text-2xl font-bold mb-4">3. Changes to Terms</h2>
            <p className="mb-4">
                We reserve the right to modify or replace these terms at any time. We will notify you of any changes by posting the new terms on the website. Your continued use of the website following the posting of any changes constitutes acceptance of those changes.
            </p>

            <h2 className="text-2xl font-bold mb-4">4. User Accounts</h2>
            <p className="mb-4">
                To access certain features of our website, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
            </p>

            <h2 className="text-2xl font-bold mb-4">5. User Responsibilities</h2>
            <p className="mb-4">
                You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
            </p>

            <h2 className="text-2xl font-bold mb-4">6. Prohibited Activities</h2>
            <p className="mb-4">
                You agree not to engage in any of the following prohibited activities:
            </p>
            <ul className="mb-4 list-disc list-inside">
                <li>Copying, distributing, or disclosing any part of the website in any medium.</li>
                <li>Using any automated system, including without limitation "robots," "spiders," or "offline readers," to access the website.</li>
                <li>Transmitting spam, chain letters, or other unsolicited email.</li>
                <li>Attempting to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the website.</li>
                <li>Taking any action that imposes, or may impose, at our sole discretion, an unreasonable or disproportionately large load on our infrastructure.</li>
                <li>Uploading invalid data, viruses, worms, or other software agents through the website.</li>
                <li>Collecting or harvesting any personally identifiable information, including account names, from the website.</li>
                <li>Publishing photos generated by artificial intelligence (AI).</li>
                <li>Posting or sharing any content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.</li>
                <li>Posting or sharing any content that is discriminatory or promotes discrimination, including but not limited to discrimination based on race, ethnicity, national origin, religion, gender, gender identity, sexual orientation, disability, or age.</li>
            </ul>

            <h2 className="text-2xl font-bold mb-4">7. User Comments and Interactions</h2>
            <p className="mb-4">
                Users may post comments and interact with other users on our platform. All interactions must adhere to the following guidelines:
            </p>
            <ul className="mb-4 list-disc list-inside">
                <li>Respectful and civil discourse is expected. Any form of abuse, harassment, or discrimination will not be tolerated.</li>
                <li>Comments and interactions should be relevant to the topic being discussed.</li>
                <li>Personal attacks, hate speech, and any form of discriminatory language are strictly prohibited. This includes but is not limited to language that is racist, sexist, homophobic, transphobic, ableist, or otherwise discriminatory.</li>
                <li>Users are encouraged to report any inappropriate behavior or content to the site administrators.</li>
            </ul>
            <h2 className="text-2xl font-bold mb-4">8. Content</h2>
            <p className="mb-4">
                You retain ownership of the content you post on the website. We will not sell, reproduce, or create derivative works from your images without your explicit consent. However, you grant us the right to use and post your images on our social media platforms.
            </p>
            <p className="mb-4">
                We reserve the right to remove any content that violates these terms or is otherwise deemed inappropriate at our sole discretion. We also reserve the right to ban users who violate these terms or engage in inappropriate behavior.
            </p>

            <h2 className="text-2xl font-bold mb-4">9. Intellectual Property and Copyright</h2>
            <p className="mb-4">
                By posting your images on our platform, you retain all copyrights to your works. We will not sell, reproduce, or create derivative works from your images without your explicit consent. However, you grant us the right to use and post your images on our social media platforms.
            </p>
            <p className="mb-4">
                All images are hosted on servers located in the United States. We ensure that our hosting provider complies with international standards for copyright protection and data security.
            </p>

            <h2 className="text-2xl font-bold mb-4">10. Termination</h2>
            <p className="mb-4">
                We may terminate or suspend your account and bar access to the website immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the terms.
            </p>

            <h2 className="text-2xl font-bold mb-4">11. Limitation of Liability</h2>
            <p className="mb-4">
                To the maximum extent permitted by applicable law, in no event shall {companyName}, its affiliates, agents, directors, employees, suppliers, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the website; (ii) any conduct or content of any third party on the website; (iii) any content obtained from the website; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
            </p>


            <h2 className="text-2xl font-bold mb-4">12. Governing Law</h2>
            <p className="mb-4">
                These terms shall be governed and construed in accordance with the laws of France, without regard to its conflict of law provisions. Any disputes relating to these terms shall be subject to the exclusive jurisdiction of the courts of France.
            </p>

            <h2 className="text-2xl font-bold mb-4">13. Contact Us</h2>
            <p className="mb-4">
                If you have any questions about these terms, please contact us at {companyEmail}.
            </p>
        </div>
    );
};

export default TermsOfService;
