// GlobalState.js
import React, { createContext, useReducer, useContext, useCallback } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from './Firebase/firebaseConfig';

const GlobalStateContext = createContext();
const GlobalDispatchContext = createContext();

const initialState = {
    userImages: [],
    userImagesFetched: false, // Indique si les images ont été récupérées
    // Ajoutez d'autres états selon vos besoins
};

const globalReducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER_IMAGES':
            return { ...state, userImages: action.payload, userImagesFetched: true };
        // Ajoutez d'autres cas selon vos besoins
        default:
            return state;
    }
};

export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(globalReducer, initialState);

    const fetchUserImages = useCallback(async (userId) => {
        if (userId !== undefined && !state.userImagesFetched) {
            const imagesCollection = collection(db, "images");
            const q = query(imagesCollection, where("author", "==", userId));
            const imagesSnapshot = await getDocs(q);
            const imagesList = imagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            dispatch({ type: 'SET_USER_IMAGES', payload: imagesList });
        }
    }, [state.userImagesFetched]);
    

    return (
        <GlobalStateContext.Provider value={{ state, fetchUserImages }}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {children}
            </GlobalDispatchContext.Provider>
        </GlobalStateContext.Provider>
    );
};

export const useGlobalState = () => {
    const context = useContext(GlobalStateContext);
    if (context === undefined) {
        throw new Error('useGlobalState must be used within a GlobalProvider');
    }
    return context;
};

export const useGlobalDispatch = () => {
    const context = useContext(GlobalDispatchContext);
    if (context === undefined) {
        throw new Error('useGlobalDispatch must be used within a GlobalProvider');
    }
    return context;
};
