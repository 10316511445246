// firebaseErrors.js
export const getFirebaseErrorMessage = (code) => {
    switch (code) {
      case 'auth/invalid-email':
        return 'The email address is badly formatted.';
      case 'auth/user-disabled':
        return 'The user account has been disabled by an administrator.';
      case 'auth/user-not-found':
        return 'There is no user record corresponding to this identifier.';
      case 'auth/wrong-password':
        return 'The password is invalid or the user does not have a password.';
      case 'auth/email-already-in-use':
        return 'The email address is already in use by another account.';
      case 'auth/operation-not-allowed':
        return 'The corresponding login provider is not enabled.';
      case 'auth/too-many-requests':
        return 'We have blocked all requests from this device due to unusual activity. Try again later.';
      case 'auth/network-request-failed':
        return 'Network request failed. Please try again.';
      case 'auth/invalid-credential':
        return 'The credential is invalid or expired. Please try again.';
      default:
        return 'An unknown error occurred. Please try again.';
    }
  };
  