import React from 'react';

const PrivacyPolicy = ({ companyName, companyEmail }) => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
            <p className="mb-4">Effective Date: June 29, 2024</p>

            <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
            <p className="mb-4">
                Welcome to {companyName}. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices with regard to your personal information, please contact us at {companyEmail}.
            </p>

            <h2 className="text-2xl font-bold mb-4">2. Information We Collect</h2>
            <p className="mb-4">
                We collect the following personal information from you:
            </p>
            <ul className="mb-4 list-disc list-inside">
                <li>Email address</li>
                <li>Username</li>
            </ul>
            <p className="mb-4">
                Additionally, we collect and store the photos you upload on our platform.
            </p>

            <h2 className="text-2xl font-bold mb-4">3. How We Use Your Information</h2>
            <p className="mb-4">
                We use personal information collected via our website for the following purposes:
            </p>
            <ul className="mb-4 list-disc list-inside">
                <li>To facilitate account creation and logon process.</li>
                <li>To manage user accounts and profiles.</li>
                <li>To enable users to post and share photos.</li>
                <li>To protect our Services.</li>
                <li>To enforce our terms, conditions, and policies.</li>
                <li>To send administrative information to you.</li>
            </ul>

            <h2 className="text-2xl font-bold mb-4">4. Storing and Processing Photos</h2>
            <p className="mb-4">
                The photos you upload are stored on Firebase servers and are processed using the Astrometry API to provide astronomical data and analysis. We ensure that your photos are protected and not used for any malicious or unauthorized purposes.
            </p>

            <h2 className="text-2xl font-bold mb-4">5. Sharing Your Information</h2>
            <p className="mb-4">
                We may process or share your data based on the following legal basis:
            </p>
            <ul className="mb-4 list-disc list-inside">
                <li>Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
                <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
                <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
                <li>Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
            </ul>

            <h2 className="text-2xl font-bold mb-4">6. Cookies</h2>
            <p className="mb-4">
                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.
            </p>

            <h2 className="text-2xl font-bold mb-4">7. Keeping Your Information Safe</h2>
            <p className="mb-4">
                We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information.
            </p>

            <h2 className="text-2xl font-bold mb-4">8. Copyright and Use of Images</h2>
            <p className="mb-4">
                By posting your images on our platform, you retain all copyrights to your works. We will not sell, reproduce, or create derivative works from your images without your explicit consent. However, you grant us the right to use and post your images on our social media platforms.
            </p>

            <h2 className="text-2xl font-bold mb-4">9. Content Moderation</h2>
            <p className="mb-4">
                We use Perspective API to moderate textual content on the Application. Perspective API helps us identify and remove objectionable content, such as harassment, threats, and hate speech. By using the Application, you consent to the use of Perspective API for content moderation.
            </p>

            <h2 className="text-2xl font-bold mb-4">10. Your Privacy Rights</h2>
            <p className="mb-4">
                In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information.
            </p>

            <h2 className="text-2xl font-bold mb-4">11. Contact Us</h2>
            <p className="mb-4">
                If you have questions or comments about this notice, you may email us at {companyEmail}.
            </p>
        </div>
    );
};

export default PrivacyPolicy;
