import React from 'react';

const Footer = () => {
    return (
        <footer className="border-t border-stone-800 bg-background text-white py-8 mt-20 ">
            <div className="container mx-auto px-4 text-center md:text-left">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                    <div>

                        <ul>
                        <li><a target='_blank' href="https://amusing-attic-3a5.notion.site/Enchant-0db60261f1f044be96b27cb8beaef124?pvs=4" className="text-gray-400 hover:text-gray-300">About</a></li>
                        <li><a target='_blank' href="https://amusing-attic-3a5.notion.site/7e6c348d4e3b4a7bb48434db46f337f3?v=bd0d05d499bf44daaf45bdaff6037872" className="text-gray-400 hover:text-gray-300">RoadMap</a></li>
                            <li><a href="/contact" className="text-gray-400 hover:text-gray-300">Contact</a></li>
                        </ul>
                    </div>
                    <div>

                        <ul>
                            <li><a href="/legal-notice" className="text-gray-400 hover:text-gray-300">Legal Notice</a></li>
                            <li><a href="/privacy-policy" className="text-gray-400 hover:text-gray-300">Privacy Policy</a></li>
                            <li><a href="/terms-of-service" className="text-gray-400 hover:text-gray-300">Terms of Service</a></li>
                            <li><a href="/eula" className="text-gray-400 hover:text-gray-300">End-user license agreement</a></li>

                        </ul>
                    </div>
                    <div>

                        <ul>
                            <li><a href="/faq" className="text-gray-400 hover:text-gray-300">FAQ</a></li>

                        </ul>
                    </div>
                </div>
                <div className="mt-8">
                    <p>&copy; {new Date().getFullYear()} Paul Joly. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
