import React from 'react';
import { Link, useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";
import { InfoCircle } from "iconsax-react";
import { useTranslation } from 'react-i18next';  // Importation du hook i18next

const AlertSuggestion = () => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { t } = useTranslation();  // Utilisation du hook pour les traductions

    return (
        <>
            <div className='flex flex-row gap-2'>
                <p className='text-primary-100 flex items-center gap-1'>
                    <div><InfoCircle size={16} /></div>
                    {t('alertSuggestion.message')}
                </p>
                <Link underline='always' size='sm' variant='flat' className='w-fit cursor-pointer text-primary-100' onPress={onOpen}>
                    {t('alertSuggestion.why')}
                </Link>
            </div>
            <Modal placement='center' className='purple-dark' isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t('alertSuggestion.modalTitle')}</ModalHeader>
                            <ModalBody>
                                <p>{t('alertSuggestion.modalBody')}</p>
                            </ModalBody>
                            <ModalFooter>

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default AlertSuggestion;