import React from 'react';
import JsonUploader from "./JsonUploader";


const Cloud = () => {
  return (

    <div className=" w-full h-screen flex flex-col items-center justify-center text-center p-6 ">
      
      <h1 className='text-3xl font-bold text-gray-100 mb-4'>Storage</h1>
      <p >
        Coming soon: Store your photos in full resolution!
      </p>
      <p className=' text-gray-400 mt-2'>
        Supported formats: .fit, .xisf, .cr2, and more...
      </p>
    </div>
  );
};

export default Cloud;
