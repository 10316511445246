import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from '@nextui-org/react';



const LoginModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate();

    return (
        <Modal isDismissable={false} hideCloseButton placement="center" backdrop='blur' isOpen={isOpen} onOpenChange={onClose} className="purple-dark text-foreground">
            <ModalContent>
                <>
                    <ModalHeader>Join the community !</ModalHeader>
                    <ModalBody>
                        <p>You need to be logged in to access this page. Please log in or create an account.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onPress={() => { navigate("/profile"); onClose(); }}>
                            Log In
                        </Button>
                        <Button auto flat onPress={() => { navigate("/signup"); onClose(); }}>
                            Sign Up
                        </Button>
                    </ModalFooter>
                </>
            </ModalContent>
        </Modal>
    );
}

export default LoginModal;
