import React, { useEffect, useState } from 'react';
import CreatableAsyncSelect from 'react-select/async-creatable';
import { Tabs, Tab, Textarea, Button, DatePicker, Checkbox, Input, Divider, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Slider, useDisclosure } from "@nextui-org/react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { ClipboardText, Microscope, TickCircle, Scanning, ArrowDown2, Setting4 } from "iconsax-react";
import { Chip } from "@nextui-org/react";
import { components } from 'react-select';
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db, auth } from "./Firebase/firebaseConfig";
import { useAptabase } from '@aptabase/react';
import ModaleCollaborative from './ModaleCollaborative'
import { useTranslation } from 'react-i18next';

const DESCRIPTION_LIMIT = 300;

const CustomInput = ({ selectProps, ...props }) => (
    selectProps.processingState ? (
        <Chip startContent={<Scanning variant="Bold" size={20} />} size="md" className="animated-gradient chip-content blob-animation">
            <p>Analyzing...</p>
        </Chip>
    ) : (
        <components.Input {...props} />
    )
);

const fetchDefaultOptions = async (collectionName) => {
    const docRef = doc(db, 'system', collectionName);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        if (collectionName in data) {
            return data[collectionName].map(value => ({
                value: value,
                label: value
            })).slice(0, 10);
        } else {
            console.log(`No such field ${collectionName} in document ${collectionName}!`);
            return [];
        }
    } else {
        console.log(`No such document ${collectionName}!`);
        return [];
    }
};


const fetchUserSetups = async () => {
    const user = auth.currentUser;
    if (user) {
        const setupsDocRef = doc(db, 'users', user.uid, 'cosmio', 'setups');
        const setupsDoc = await getDoc(setupsDocRef);
        if (setupsDoc.exists()) {
            return setupsDoc.data();
        }
    }
    return {};
};

const UploadForm = ({
    isLoaded,
    searchBoxRef,
    locationName,
    isImageLoading,
    setLocationName,
    handlePlaceChanged,
    metadata,
    setMetadata,
    customStyles,
    objects,
    setObjects,
    handleCreateOption,
    loadOptions,
    handleCancelUpload,
    handlePostImage,
    processingState,
    descriptionError,
    setDescriptionError,
    brightness,
    contrast,
    saturation,
    handleBrightnessChange,
    handleContrastChange,
    handleSaturationChange,
    resetTransformations,
    loading,
    realtimeObjects,
}) => {
    const [defaultOptions, setDefaultOptions] = useState({
        Object: objects,
        Telescope: [],
        Camera: [],
        Software: [],
        Accessory: [],
        Filter: [],
        Mount: []
    });
    const { t } = useTranslation();
    const [setups, setSetups] = useState([]);
    const [selectedSetup, setSelectedSetup] = useState(t("nosetup"));
    const [isNewSetup, setIsNewSetup] = useState(false);
    const [newSetupName, setNewSetupName] = useState('');
    const [isEquipmentEmpty, setIsEquipmentEmpty] = useState(true);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [observationDate, setObservationDate] = useState(null);

    const { trackEvent } = useAptabase();
 

    useEffect(() => {
        const fetchOptions = async () => {
            const optionCollections = ['object', 'telescope', 'camera', 'software', 'filter', 'accessory', 'mount'];
            const fetchedOptions = {};
            for (const collection of optionCollections) {
                fetchedOptions[collection] = await fetchDefaultOptions(collection);
            }
            setDefaultOptions(prevOptions => ({
                ...prevOptions,
                ...fetchedOptions,
                Object: objects // Utilisez `objects` pour les options par défaut de l'objet
            }));
        };
        fetchOptions();

        const fetchSetups = async () => {
            const userSetups = await fetchUserSetups();
            setSetups(Object.entries(userSetups));
        };
        fetchSetups();
    }, [objects]);

    const handleCreateNewOption = async (inputValue, field) => {
        switch (field) {
            case 'Object':
                setObjects(prevObjects => [...prevObjects, { value: inputValue, label: inputValue }]);
                break;
            case 'Telescope':
            case 'Camera':
            case 'Mount':
            case 'Accessory':
            case 'Filter':
            case 'Software':
                setMetadata(prevMetadata => ({
                    ...prevMetadata,
                    [field]: [...(prevMetadata[field] || []), { value: inputValue, label: inputValue }]
                }));
                break;
            default:
                break;
        }
    };

    const handleLocationChange = (e) => {
        const { value } = e.target;
        setLocationName(value);
        setMetadata(prevMetadata => ({
            ...prevMetadata,
            Location: value
        }));
    };

    const handleObjectChange = (selectedOptions) => {
        setObjects(selectedOptions || []);
    };

    const transformSetupData = (setupData) => {
        const transform = (data) => data.map(item => ({ value: item, label: item }));
        return {
            Telescope: transform(setupData.telescope || []),
            Camera: transform(setupData.camera || []),
            Filter: transform(setupData.filter || []),
            Accessory: transform(setupData.accessory || []),
            Software: transform(setupData.software || []),
            Mount: transform(setupData.mount || [])
        };
    };

    const handleSelectSetup = (setupName) => {
        if (setupName === t("nosetup")) {
            handleDeselectSetup();
        } else {
            const setup = setups.find(([name]) => name === setupName);
            if (setup) {
                const transformedData = transformSetupData(setup[1]);
                setMetadata({
                    ...metadata,
                    ...transformedData
                });
            }
        }
    };

    const handleCreateNewSetup = async () => {
        const user = auth.currentUser;
        if (user && newSetupName) {
            const newSetupData = {
                telescope: metadata.Telescope.map(item => item.value),
                camera: metadata.Camera.map(item => item.value),
                filter: metadata.Filter.map(item => item.value),
                accessory: metadata.Accessory.map(item => item.value),
                software: metadata.Software.map(item => item.value),
                mount: metadata.Mount.map(item => item.value),
            };

            await setDoc(doc(db, 'users', user.uid, 'cosmio', 'setups'), {
                [newSetupName]: newSetupData
            }, { merge: true });

            trackEvent('setup', { name: newSetupName });
            setSetups(prevSetups => [...prevSetups, [newSetupName, newSetupData]]);
            setSelectedSetup(newSetupName);
            setIsNewSetup(false);
            setNewSetupName('');
        }
    };

    const handleDeselectSetup = () => {
        setSelectedSetup(t("nosetup"));
        setMetadata({
            ...metadata,
            Telescope: [],
            Camera: [],
            Filter: [],
            Accessory: [],
            Software: [],
            Mount: []
        });
    };

    const handleConfirmPublish = () => {
        handlePostImage();
        handleCreateNewSetup();
    };

    useEffect(() => {
        const checkEquipmentEmpty = () => {
            setIsEquipmentEmpty(
                (!metadata.Telescope || metadata.Telescope.length === 0) &&
                (!metadata.Camera || metadata.Camera.length === 0) &&
                (!metadata.Mount || metadata.Mount.length === 0) &&
                (!metadata.Accessory || metadata.Accessory.length === 0) &&
                (!metadata.Filter || metadata.Filter.length === 0) &&
                (!metadata.Software || metadata.Software.length === 0)
            );
        };

        checkEquipmentEmpty();
    }, [metadata]);

    useEffect(() => {
        if (observationDate) {
            setMetadata({ ...metadata, ObservationDate: observationDate });
        }
    }, [observationDate, metadata]);

    return (
        <div className="flex flex-col w-full basis-1/2  justify-between min-h-[80vh]">

            <div>
                <Tabs
                    variant="underlined"
                    className="dark"
                    classNames={{
                        tabContent: "group-data-[selected=true]:text-[hsl(var(--text-foreground))] ",
                    }}
                >
                    <Tab key="general"
                        title={
                            <div className="flex items-center space-x-1">
                                <ClipboardText size={18} variant="Bold" />
                                <span>{t("general")}</span>
                            </div>
                        }
                    >
                        <div className="tab-content">
                            <div>
                                <label className="label-async">{t("object")}</label>
                                <CreatableAsyncSelect
                                    isMulti
                                    cacheOptions
                                    loadOptions={(inputValue, callback) => loadOptions(inputValue, callback, 'object')}
                                    defaultOptions={defaultOptions.Object}
                                    value={objects}
                                    onChange={handleObjectChange}
                                    onCreateOption={(inputValue) => handleCreateNewOption(inputValue, 'Object')}
                                    placeholder={processingState ? "" : "Andromeda..."}
                                    styles={customStyles}
                                    components={{ Input: (inputProps) => <CustomInput {...inputProps} processingState={processingState} /> }}
                                    processingState={processingState}
                                />
                                {processingState && (
                                    <p className="text-sm text-primary-200 mt-1">{t("wait")}</p>
                                )}
                            </div>
                            <div>
                                <label className="label-async">{t("description")}</label>
                                <Textarea
                                    className="dark"
                                    placeholder="On a starry night..."
                                    value={metadata.Description}
                                    isInvalid={!!descriptionError}
                                    errorMessage={descriptionError}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        if (value.length <= DESCRIPTION_LIMIT) {
                                            setMetadata({ ...metadata, Description: value });
                                            setDescriptionError("");
                                        } else {
                                            setDescriptionError(`Description cannot exceed ${DESCRIPTION_LIMIT} characters`);
                                        }
                                    }}
                                />
                            </div>

                            <div>
                                <label className="label-async">{t("observationdate")} (mm/dd/yyyy)</label>
                                <DatePicker
                                    className='dark'
                                    value={observationDate}
                                    onChange={setObservationDate}
                                />
                            </div>

                            <div>
                                <label className="label-async">{t("location")}</label>
                                {isLoaded ? (
                                    <StandaloneSearchBox
                                        onLoad={ref => searchBoxRef.current = ref}
                                        onPlacesChanged={handlePlaceChanged}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Paris..."
                                            value={locationName}
                                            onChange={handleLocationChange}
                                            className="location-input"
                                        />
                                    </StandaloneSearchBox>
                                ) : null}
                            </div>
                        </div>
                    </Tab>
                    <Tab
                        title={
                            <div className="flex items-center space-x-1">
                                <Microscope size={18} variant="Bold" />
                                <span>{t("equipment")}</span>
                            </div>
                        }
                        key="equipment"
                    >
                        <div>
                            <Dropdown isDisabled={setups.length === 0} className="dark">
                                <DropdownTrigger endContent={<ArrowDown2 size={16} />} >
                                    <Button variant="light">
                                        {selectedSetup}
                                    </Button>
                                </DropdownTrigger>
                                <DropdownMenu aria-label="Select equipement setup">
                                    <DropdownItem
                                        key="none"
                                        onClick={() => {
                                            setSelectedSetup( t("nosetup"));
                                            handleDeselectSetup();
                                        }}
                                    >
                                        {t("nosetup")}
                                    </DropdownItem>
                                    {setups.map(([name]) => (
                                        <DropdownItem
                                            key={name}
                                            onClick={() => {
                                                setSelectedSetup(name);
                                                handleSelectSetup(name);
                                            }}
                                        >
                                            {name}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        <Divider className="my-4" />
                        <div className="tab-content">

                            <ModaleCollaborative />

                            {["Telescope", "Camera", "Mount", "Accessory", "Filter", "Software"].map(key => {
                                let placeholderText;
                                switch (key) {
                                    case "Telescope":
                                        placeholderText = "Celestron C8...";
                                        break;
                                    case "Camera":
                                        placeholderText = "ZWO ASI224MC...";
                                        break;
                                    case "Mount":
                                        placeholderText = "NextStar...";
                                        break;
                                    case "Accessory":
                                        placeholderText = "Barlow...";
                                        break;
                                    case "Filter":
                                        placeholderText = "Baader...";
                                        break;
                                    case "Software":
                                        placeholderText = "PHD2...";
                                        break;
                                    default:
                                        placeholderText = `Enter ${key}`;
                                }
                                return (
                                    <div key={key}>
                                        <label className="label-async">{key}</label>
                                        <CreatableAsyncSelect
                                            isMulti
                                            cacheOptions
                                            loadOptions={(inputValue, callback) => loadOptions(inputValue, callback, key.toLowerCase())}
                                            defaultOptions={defaultOptions[key.toLowerCase()]}
                                            value={metadata[key]}
                                            onChange={(selectedOptions) => setMetadata({ ...metadata, [key]: selectedOptions || [] })}
                                            onCreateOption={(inputValue) => handleCreateNewOption(inputValue, key)}
                                            placeholder={placeholderText}
                                            styles={customStyles}

                                        />

                                    </div>
                                );
                            })}

                            <div>
                                {selectedSetup === t("nosetup") && (
                                    <div className="setup-container mt-2">
                                        <Checkbox
                                            isSelected={isNewSetup}
                                            onChange={() => setIsNewSetup(!isNewSetup)}
                                            isDisabled={isEquipmentEmpty}
                                        >
                                            {t("createnewsetup")}
                                           
                                        </Checkbox>
                                    </div>
                                )}
                                {isNewSetup && (
                                    <div className="mt-2">
                                        <label className="label-async">Setup name</label>
                                        <Input
                                            placeholder="Setup 1..."
                                            value={newSetupName}
                                            onChange={(e) => setNewSetupName(e.target.value)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Tab>
                    <Tab key="settings"
                        title={
                            <div className="flex items-center space-x-1">
                                <Setting4 size={18} variant="Bold" />
                                <span>{t("settingstitle")}</span>
                            </div>
                        }>
                        <div className="flex flex-col w-full gap-4">


                            <Slider
                                label={t("brightness")}
                                color="foreground"
                                size="sm"
                                minValue={-1}
                                maxValue={1}
                                step={0.01}
                                defaultValue={0}
                                value={brightness}
                                aria-label="Brightness"
                                onChange={handleBrightnessChange}
                                fillOffset={0}
                                formatOptions={{ signDisplay: 'always' }}

                            />


                            <Slider
                                label={t("contrast")}
                                size="sm"
                                fillOffset={0}
                                minValue={-1}
                                maxValue={1}
                                step={0.01}
                                defaultValue={0}
                                value={contrast}
                                aria-label="Contrast"
                                onChange={handleContrastChange}
                                color="foreground"
                                formatOptions={{ signDisplay: 'always' }}
                            />


                            <Slider
                                label={t("saturation")}
                                color="foreground"
                                size="sm"
                                fillOffset={0}
                                minValue={-1}
                                maxValue={1}
                                step={0.01}
                                defaultValue={0}
                                value={saturation}
                                aria-label="Saturation"
                                onChange={handleSaturationChange}
                                formatOptions={{ signDisplay: 'always' }}
                            />

                            <Button variant='flat' size='sm' onClick={resetTransformations} >{t('cancelmodif')}</Button>
                        </div>
                    </Tab>
                </Tabs>
            </div>

            <div className="flex flex-row gap-2 w-full justify-end mt-4">
                <Button className="w-full" onClick={handleCancelUpload}>
                {t('cancel')}
                </Button>
                <Button isLoading={loading} isDisabled={isImageLoading}  className="w-full" color="primary" onClick={handleConfirmPublish}>
                {t('publish')}
                </Button>
            </div>
        </div >
    );
};

export default UploadForm;
