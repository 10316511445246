import React, { useState, useContext } from 'react';
import {  Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import Planetary1 from '../assets/images/badges/planetary_1.svg';
import Planetary2 from '../assets/images/badges/planetary_2.svg';
import Planetary3 from '../assets/images/badges/planetary_3.svg';
import Deepsky1 from '../assets/images/badges/deepsky_1.svg';
import Deepsky2 from '../assets/images/badges/deepsky_2.svg';
import Deepsky3 from '../assets/images/badges/deepsky_3.svg';
import { ArrowRight2 } from 'iconsax-react';
import UserContext from "./UserContext";
import { useTranslation } from 'react-i18next';


const getBadge = (badge, level) => {
    switch (badge) {
        case 'planetary':
            switch (level) {
                case 1: return Planetary1;
                case 2: return Planetary2;
                case 3: return Planetary3;
                default: return null;
            }
        case 'deepsky':
            switch (level) {
                case 1: return Deepsky1;
                case 2: return Deepsky2;
                case 3: return Deepsky3;
                default: return null;
            }
        default: return null;
    }
};




const getBadgePhotosRequirement = (badge, level) => {
    let photos;
    switch (level) {
        case 0:
            photos = 10;
            break;
        case 1:
            photos = 50;
            break;
        case 2:
            photos = 100;
            break;
        default:
            return '';
    }
    return photos;
};

const getBadgeLikesRequirement = (badge, level) => {
    let likes;
    switch (level) {
        case 0:
            likes = 10;
            break;
        case 1:
            likes = 20;
            break;
        case 2:
            likes = 40;
            break;
        default:
            return '';
    }
    return likes;
};



const getBadgeLevelName = (badge, level) => {
    let levelName;
    switch (level) {
        case 1: levelName = 'I'; break;
        case 2: levelName = 'II'; break;
        case 3: levelName = 'III'; break;
        default: levelName = '';
    }
    return badge === 'planetary' ? `Pioneer ${levelName}` : `Explorer ${levelName}`;
};


const BadgeDisplay = ({ badges, profileUser }) => {
    const [visible, setVisible] = useState(false);
    const [activeBadge, setActiveBadge] = useState(null);
    const { currentUser } = useContext(UserContext);
    const { t } = useTranslation();

    const handleOpenModal = (badge, level) => {
        setActiveBadge({ badge, level });
        setVisible(true);
    };

    const handleCloseModal = () => {
        setVisible(false);
        setActiveBadge(null);
    };

    return (
        <div className="badge-container">
            {['planetary', 'deepsky'].map(badge => {
                const level = badges[badge] || 0;
                const badgeImage = getBadge(badge, level);
            

                return (
                    <div key={badge} className="cursor-pointer badge-wrapper"   {...(currentUser.uid === profileUser.id && { onClick: () => handleOpenModal(badge, level) })}>
                        <img src={badgeImage || getBadge(badge, level + 1)} alt={`${badge} level ${level || level + 1}`} className={`badge ${!badgeImage ? 'greyscale' : 'alt'} transition-transform duration-300 w-14 ease-in-out hover:scale-110 `} />
                    </div>

                );
            })}
            {activeBadge && (
                <Modal placement="center" isOpen={visible} onOpenChange={handleCloseModal} className="purple-dark text-foreground text-center">
                    <ModalContent>
                        <ModalHeader className='text-center' > {t('achievements')}</ModalHeader>
                        <ModalBody className="badge-modal-wrapper">
                            <div className="badge-3d-container flex justify-center items-center gap-2">

                                {activeBadge.level < 3 && (
                                    <div className="flex items-center justify-center w-1/3">
                                    </div>
                                )}


                                {activeBadge.level > 0 && (
                                    <div className="flex items-center justify-center w-1/3">
                                        <img
                                            src={getBadge(activeBadge.badge, activeBadge.level)}
                                            alt={`${activeBadge.badge} level ${activeBadge.level}`}
                                            className="current-badge"
                                        />
                                    </div>
                                )}


                                {activeBadge.level < 3 && activeBadge.level > 0 &&(
                                    <div className="flex items-center w-1/3">
                                        <ArrowRight2 color='hsl(var(--nextui-default-500))' size={16} variant='Bold' />
                                        <img
                                            src={getBadge(activeBadge.badge, activeBadge.level + 1)}
                                            alt={`${activeBadge.badge} level ${activeBadge.level + 1}`}
                                            className="next-level"
                                        />
                                    </div>
                                )}



                            </div>
                            {getBadgeLevelName(activeBadge.badge, activeBadge.level) && (
                                <p className="badge-level-name text-xl ">{getBadgeLevelName(activeBadge.badge, activeBadge.level)}</p>
                            )}
                            {activeBadge.level < 3 ? (
                                <p className="badge-requirement text-default-500 ">
                              
                                    To reach the next level, you need to have {getBadgePhotosRequirement(activeBadge.badge, activeBadge.level)} photos with {getBadgeLikesRequirement(activeBadge.badge, activeBadge.level)} likes each.
                                </p>
                            ) : (
                                <p className="badge-requirement text-default-500 ">
                                    {t('congrats')}
                                </p>
                            )}
                        </ModalBody>

                        <ModalFooter>

                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </div>
    );
};

export default BadgeDisplay;
