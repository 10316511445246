import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../components/Firebase/firebaseConfig";
import { Button } from '@nextui-org/react';

const AlertSystem = () => {
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        const checkForAlert = async () => {
            try {
                const storedAlertDismissed = localStorage.getItem('alertDismissed');

                // Si l'alerte n'a pas été déjà rejetée, vérifier Firestore
                if (!storedAlertDismissed) {
                    const alertDocRef = doc(db, 'system', 'alert');
                    const alertDocSnapshot = await getDoc(alertDocRef);

                    if (alertDocSnapshot.exists()) {
                        const alertData = alertDocSnapshot.data();
                        if (alertData.show && alertData.message) {
                            setAlertMessage(alertData.message);
                            setIsAlertVisible(true);
                        }
                    }
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données d\'alerte depuis Firestore:', error);
            }
        };

        checkForAlert(); // Vérifie l'alerte lors du montage du composant
    }, []);

    const dismissAlert = () => {
        localStorage.setItem('alertDismissed', 'true');
        setIsAlertVisible(false);
    };

    return (
        <>
            {isAlertVisible && (
                <div className="w-full text-foreground bg-warning-800 text-center py-2 ps-4 pe-4 z-50">
                    <div className="flex justify-between items-center">
                        <p className='text-warning-100 font-medium'>{alertMessage}</p>
                        <Button color="warning" onPress={dismissAlert}>
                            Close
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default AlertSystem;
