import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "./Firebase/firebaseConfig";
import { collection, query, where, onSnapshot, updateDoc, deleteDoc, doc, getDocs, addDoc } from "firebase/firestore";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Button, getKeyValue, User, Pagination, Modal, Input, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";
import UserContext from "./UserContext";
import { TickSquare, Trash } from "iconsax-react";
import { formatDistanceToNow } from "date-fns";

const Admin = () => {
  const [unpublishedPhotos, setUnpublishedPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState(new Set());
  const [totalPhotos, setTotalPhotos] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [photoToDelete, setPhotoToDelete] = useState(null);
  const [deleteReason, setDeleteReason] = useState("");

  useEffect(() => {
    if (!currentUser || !currentUser.isAdmin) {
      navigate('/');  // Redirect to home if user is not an admin
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (currentUser && currentUser.isAdmin) {
      const photosCollection = collection(db, "images");
      const q = query(photosCollection, where("state", "==", false));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const photos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setUnpublishedPhotos(photos);
      });

      const totalPhotosQuery = query(photosCollection);
      getDocs(totalPhotosQuery).then(snapshot => {
        setTotalPhotos(snapshot.size);
      });

      const usersCollection = collection(db, "users");
      getDocs(usersCollection).then(snapshot => {
        setTotalUsers(snapshot.size);
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const sendNotification = async (recipientId, message, imageId) => {
    const notificationsCollection = collection(db, "notifications");
    await addDoc(notificationsCollection, {
      recipientId,
      message,
      read: false,
      imageId,
      timestamp: new Date(),
    });
  };

  const publishPhotos = async () => {
    const promises = Array.from(selectedPhotos).map(photoId => {
      const photoDoc = doc(db, "images", photoId);
      return updateDoc(photoDoc, { state: true }).then(() => {
        const photo = unpublishedPhotos.find(photo => photo.id === photoId);
        return sendNotification(photo.author, `Your photo has been published!`, photoId);
      });
    });

    await Promise.all(promises);
    setSelectedPhotos(new Set());
  };

  const publishSinglePhoto = async (photoId) => {
    const photoDoc = doc(db, "images", photoId);
    await updateDoc(photoDoc, { state: true });
    const photo = unpublishedPhotos.find(photo => photo.id === photoId);
    await sendNotification(photo.author, `Your photo has been published!`, photoId);
    setUnpublishedPhotos(prevPhotos => prevPhotos.filter(photo => photo.id !== photoId));
  };



  const deleteSinglePhoto = (photoId) => {
    setPhotoToDelete(photoId);
    setDeleteModalOpen(true);
  };

  const confirmDeletePhoto = async () => {
    if (photoToDelete) {
      const photoDoc = doc(db, "images", photoToDelete);
      await deleteDoc(photoDoc);
      const photo = unpublishedPhotos.find(photo => photo.id === photoToDelete);
      await sendNotification(photo.author, `Your photo has been deleted. Reason: ${deleteReason}`, photoToDelete);
      setUnpublishedPhotos(prevPhotos => prevPhotos.filter(photo => photo.id !== photoToDelete));
      setPhotoToDelete(null);
      setDeleteReason("");
      setDeleteModalOpen(false);
    }
  };

  const handleSelectionChange = (selected) => {
    setSelectedPhotos(new Set(selected));
  };

  const formatDistance = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp.seconds * 1000), { addSuffix: true });
  };

  const columns = [
    { key: "photo", label: "PHOTO" },
    { key: "description", label: "DESCRIPTION" },
    { key: "object", label: "OBJECT" },
    { key: "author", label: "UPLOADER" },
    { key: "date", label: "DATE POSTED" },
    { key: "actions", label: "ACTIONS" },
  ];

  const rows = unpublishedPhotos.map((photo) => ({
    key: photo.id,
    photo: <img src={photo.imageUrl} alt={photo.title} style={{ width: '100px', height: 'auto', borderRadius: '8px' }} />,
    title: photo.title,
    description: photo.metadata?.Description ?? "No description", // Safe access
    object: photo.objects?.join(', ') ?? "No objects", // Safe access
    author: <User name={photo.username} />,
    date: formatDistance(photo.timestamp),
    actions: (
      <div>
        <Button variant="light" isIconOnly color="success" onClick={() => publishSinglePhoto(photo.id)}><TickSquare variant="Bold" size={24} /></Button>
        <Button variant="light" isIconOnly color="error" onClick={() => deleteSinglePhoto(photo.id)} style={{ marginLeft: '10px' }}><Trash variant="Bold" size={24} /></Button>
      </div>
    ),
  }));

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rows.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="w-full h-screen flex flex-col gap-6">
      <div>
        <h1 className="text-3xl font-bold mb-4">Admin Panel</h1>
        <div className="flex justify-around my-2 items-center gap-2">
          <div className="flex flex-col gap-1 items-center bg-default-50 p-4 rounded-lg w-1/2">
            <p className="text-2xl text-primary-100 font-bold">{totalPhotos}</p>
            <p className="text-default-400">Total Photos</p>
          </div>
          <div className="flex flex-col gap-1 items-center bg-default-50 p-4 rounded-lg w-1/2">
            <p className="text-2xl text-primary-100 font-bold">{totalUsers}</p>
            <p className="text-default-400">Total Users</p>
          </div>
        </div>
      </div>
      <div>
        <div className="flex justify-between my-2 items-center">
          <h2 className="text-md font-bold">{unpublishedPhotos.length} Pending Photos</h2>
          <div>
            <Button color="success" onClick={publishPhotos} isDisabled={selectedPhotos.size === 0}>
              Publish All
            </Button>
          </div>
        </div>
        <div>
          <Table
            selectionMode="multiple"
            selectedKeys={selectedPhotos}
            onSelectionChange={handleSelectionChange}
            className="dark overflow-auto"
            removeWrapper
            aria-label="Unpublished Photos Table"
          >
            <TableHeader>
              {columns.map((column) => (
                <TableColumn key={column.key}>{column.label}</TableColumn>
              ))}
            </TableHeader>
            <TableBody>
              {currentItems.map((row) => (
                <TableRow key={row.key}>
                  {(columnKey) => <TableCell>{getKeyValue(row, columnKey)}</TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            showControls
            total={Math.ceil(rows.length / itemsPerPage)}
            initialPage={currentPage}
            onChange={(page) => setCurrentPage(page)}
            className="flex mt-4 m-auto w-full justify-center"
            variant="light"
          />
        </div>
      </div>

      <Modal className="dark" isOpen={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)} size="lg">
        <ModalContent>
          <ModalHeader className="flex-col gap-1">
            Delete Photo
          </ModalHeader>
          <ModalBody>
            <Input
              clearable
              fullWidth
              color="error"
              size="lg"
              placeholder="Reason for deletion"
              value={deleteReason}
              onChange={(e) => setDeleteReason(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button auto flat color="error" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>
            <Button auto onClick={confirmDeletePhoto}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Admin;
