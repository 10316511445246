import React from 'react';
import { Snippet } from '@nextui-org/react';

const Contact = () => {
  
    return (
        <div className='min-h-screen'>
            <h1 className='text-3xl font-bold mb-4 '>Get it Touch</h1>
            <p className='mb-4'>
                If you have any questions or want to be a partner and showcase your products, click the button below to copy my email address to your clipboard.
            </p>
            <Snippet  size='md' symbol=""   >contact@starmaze.io</Snippet>
        </div>
    );
};

export default Contact;
