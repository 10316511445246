import React, { useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from './UserContext';
import { Notification, Setting2 } from 'iconsax-react';
import { Avatar, Button } from "@nextui-org/react";
import { collection, query, where, onSnapshot, getDocs } from "firebase/firestore";
import { db } from "./Firebase/firebaseConfig";
import SearchBar from './SearchBar';
import SearchContext from './SearchContext';
import Notifications from './Notifications';
import SettingsModal from "./SettingsModal";

const TopNavBar = () => {
    const { currentUser } = useContext(UserContext);
    const { allData, allUsers, setAllData, setAllUsers, setResults, setResultsCount, setSearching, sortOrder } = useContext(SearchContext);
    const navigate = useNavigate();
    const [unreadCount, setUnreadCount] = useState(0);
    const [showNotifications, setShowNotifications] = useState(false); // État pour afficher les notifications
    const notificationsRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    useEffect(() => {
        if (currentUser) {
            const notificationsCollection = collection(db, "notifications");
            const q = query(notificationsCollection, where("recipientId", "==", currentUser.uid), where("read", "==", false));

            const unsubscribe = onSnapshot(q, (snapshot) => {
                setUnreadCount(snapshot.docs.length);
            });

            return () => unsubscribe();
        }
    }, [currentUser]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const imagesCollection = collection(db, "images");
                const usersCollection = collection(db, "users");

                const [imagesSnapshot, usersSnapshot] = await Promise.all([
                    getDocs(imagesCollection),
                    getDocs(usersCollection),
                ]);

                const imageData = imagesSnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(image => image.state === true && image.publish === true); // Filtrer les images

                const userData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                setAllData(imageData);
                setAllUsers(userData);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [setAllData, setAllUsers]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
                setShowNotifications(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSettingsModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const extractMetadataValues = useCallback((metadata) => {
        const values = [];
        for (const [key, value] of Object.entries(metadata)) {
            if (key === 'Description') continue; // Exclude description
            if (Array.isArray(value)) {
                value.forEach(item => values.push({ group: key, value: item }));
            } else if (typeof value === 'string') {
                values.push({ group: key, value });
            }
        }
        return values;
    }, []);

    const handleNavigate = (path) => {
        navigate(path);
    };

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications); // Inverser l'état pour afficher ou masquer les notifications
    };

    return (
        <div className="static top-8 right-20 flex flex-row  items-center justify-between mb-6">
            <div className="flex-1 mr-4  md:max-w-fit md:min-w-80 ">
                <SearchBar
                    allData={allData}
                    allUsers={allUsers}
                    setResults={setResults}
                    setResultsCount={setResultsCount}
                    setSearching={setSearching}
                    extractMetadataValues={extractMetadataValues}
                    sortOrder={sortOrder}
                />
            </div>

            <div >
                {currentUser ? (
                    <>
                        <div className='flex flex-row items-center gap-4' >
                            <div className='flex flex-row items-center gap-2' >

                                <div
                                    className="flex justify-center relative cursor-pointer w-[40px] "
                                    onClick={toggleNotifications}
                                >
                                    <div className={`notification-icon ${unreadCount > 0 ? 'notification-ring' : ''}`}>
                                        <Notification size={22} variant={unreadCount > 0 ? "Bold" : "Linear"} />
                                        {unreadCount > 0 && <div className="notification-badge" />}
                                    </div>

                                    {showNotifications && (
                                        <div ref={notificationsRef} className="shadow-2xl absolute top-full mt-2 right-0 z-50 w-64 lg:w-96 ">
                                            <Notifications />
                                        </div>
                                    )}
                                </div>

                                {currentUser.uid && (

                                    <Button isIconOnly variant="light" onClick={handleSettingsModal}>
                                        <Setting2 size={20} className="text-foreground" variant="Bold" />
                                    </Button>

                                )}

                            </div>


                            <Avatar
                                src={currentUser.profilePicture}
                                className="text-tiny cursor-pointer"
                                onClick={() => handleNavigate(`/profile/${currentUser.username}`)}
                            />


                        </div>
                    </>
                ) : (
                    <>
                        <div className='flex flex-row items-center gap-4' >

                            <div className="cursor-pointer " onClick={() => handleNavigate('/login')}>
                                Login
                            </div>
                            <div className="cursor-pointer " onClick={() => handleNavigate('/signup')}>
                                Signup
                            </div>
                        </div>
                    </>
                )}
            </div>
            <SettingsModal isOpen={isModalOpen} onClose={handleSettingsModal} />

        </div >

    );
};

export default TopNavBar;
