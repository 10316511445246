// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import {  initializeAuth, browserLocalPersistence } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyARmd6-70E4YD8iPCWrYctvrMidnu-6ypM",
  authDomain: "starmaze-d4663.firebaseapp.com",
  projectId: "starmaze-d4663",
  storageBucket: "starmaze-d4663.appspot.com",
  messagingSenderId: "123385718679",
  appId: "1:123385718679:web:ebe8dc0ee5526ba20086d2",
  measurementId: "G-RGHK0YB1B3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = initializeAuth(app, { persistence: browserLocalPersistence });
const db = getFirestore(app);

export { app, analytics, storage, auth, db};
