import React, { useEffect, useState } from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { GoogleGenerativeAI } from "@google/generative-ai";

// The Tutorial component generates and displays a detailed tutorial for photographing a celestial object
// based on the provided objectName. It uses Google's Generative AI to fetch the tutorial and displays 
// it within a modal.
const Tutorial = ({ objectName }) => {
    // Using useState to manage the state of the fetched event text and the text to be displayed.
    const [eventText, setEventText] = useState('');
    const [displayText, setDisplayText] = useState('');
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    // Asynchronous function to fetch the event text from the Google Generative AI API.
    const fetchEventText = async () => {
        try {
            const API_KEY = "YOUR_API_KEY_HERE";
            const genAI = new GoogleGenerativeAI(API_KEY);
            const model = genAI.getGenerativeModel({
                model: "gemini-1.5-flash",
                systemInstruction: "You speak to professional astronomers, so don't bullshit.",
            });

            // Creating a prompt to generate a detailed tutorial for photographing the celestial object.
            const prompt = `Give a detailed tutorial on how to photograph, (example of telescope and astro camera, settings:exposition, exposure time) that's planetary or deepsky, so detect the good one. ${objectName}. (max 2000 characters)`;

            // Fetching the generated content from the model.
            const result = await model.generateContentStream([prompt]);
            let fullText = '';
            for await (const chunk of result.stream) {
                fullText += chunk.text();
            }

            const resultText = fullText.trim();

            // Setting the event text if the result is valid.
            if (resultText) {
                setEventText(resultText);
            } else {
                setEventText('');
            }
        } catch (error) {
            // Handling any errors that occur during the fetch.
            console.error("Error fetching event:", error);
            setEventText("Error fetching event.");
        }
    };

    // Handle the button click to open the modal and fetch the event text.
    const handleButtonClick = async () => {
        onOpen();
        await fetchEventText();
    };

    useEffect(() => {
        // Creating a typing animation effect for the fetched event text.
        if (eventText && eventText !== "Error fetching event.") {
            let index = 0;
            const interval = setInterval(() => {
                setDisplayText(eventText.slice(0, index + 1));
                index++;
                if (index >= eventText.length) {
                    clearInterval(interval);
                }
            }, 10); // Adjust the speed of the typing effect here
            return () => clearInterval(interval); // Clear interval on component unmount
        }
    }, [eventText]);

    // Helper function to render text with formatting
    const renderFormattedText = (text) => {
        // Convert new lines to <br/> for proper spacing
        // Convert asterisks to <strong> tags for bold
        return text
            .split('\n')
            .map((line, index) => (
                <p key={index}>
                    {line.split(/(\*\*.*?\*\*)/g).map((part, i) =>
                        part.startsWith('**') && part.endsWith('**') ? (
                            <strong key={i}>{part.slice(2, -2)}</strong>
                        ) : (
                            part
                        )
                    )}
                </p>
            ));
    };

    // Rendering the button to generate the tutorial and the modal to display the tutorial.


    return (
        <>
            <Button className='gradient-hover bg-default-100 w-fit ' size='sm' onPress={handleButtonClick}>
                <div>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.323443 6.95042L2.69536 7.75064C3.89808 8.15794 4.84445 9.10192 5.24935 10.3046L6.04958 12.6766C6.19572 13.1078 6.80428 13.1078 6.95042 12.6766L7.75064 10.3046C8.15794 9.10192 9.10192 8.15555 10.3046 7.75064L12.6766 6.95042C13.1078 6.80428 13.1078 6.19572 12.6766 6.04958L10.3046 5.24936C9.10192 4.84206 8.15555 3.89808 7.75064 2.69536L6.95042 0.323443C6.80428 -0.107814 6.19572 -0.107814 6.04958 0.323443L5.24935 2.69536C4.84206 3.89808 3.89808 4.84445 2.69536 5.24936L0.323443 6.04958C-0.107814 6.19572 -0.107814 6.80428 0.323443 6.95042Z" fill="white" />
                    </svg>
                </div>
                Generate Tutorial</Button>
            <Modal placement='center' size='xl' scrollBehavior='inside' className='dark h-1/2' isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Tutorial for {objectName}</ModalHeader>
                            <ModalBody>
                                {renderFormattedText(displayText)}
                            </ModalBody>
                            <ModalFooter>

                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default Tutorial;
