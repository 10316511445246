import React, { useEffect, useState, useRef, useContext } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Share } from '@capacitor/share';
import { Helmet } from 'react-helmet-async';

import { Link, useNavigate, useLocation } from "react-router-dom";
import { doc, getDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { db } from "../components/Firebase/firebaseConfig";
import { Button, Chip, Switch, Tooltip, User, Divider, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, ScrollShadow, Modal, ModalContent, Avatar } from "@nextui-org/react";
import CommentSection from "./CommentSection";
import LikeButton from "./LikeButton";

import CustomChip from '../components/CustomChip';
import { Maximize3, Image, Scanning, Edit2, ImportCurve, More, Trash, CloseCircle, Flag, ExportCurve } from "iconsax-react";
import UserContext from "../components/UserContext";
import { formatDistanceToNow } from "date-fns";
import AstronomicalEvent from "./CompleteNameObject";
import Tutorial from "./Tutorial";
import { useTranslation } from 'react-i18next';  // Importation du hook i18next


const ImageDetails = ({ imageId }) => {
    const navigate = useNavigate(); // Hook to navigate programmatically
    const location = useLocation(); // Hook to access current location

    const [image, setImage] = useState(null); // State to hold the image data
    const [showAnnotated, setShowAnnotated] = useState(false); // State to toggle annotated image view
    const [annotatedAvailable, setAnnotatedAvailable] = useState(false); // State to check if annotated image is available
    const imageRef = useRef(null); // Ref to hold image element
    const commentInputRef = useRef(null); // Ref to hold comment input element

    const { currentUser } = useContext(UserContext); // Context to get the current user
    const storage = getStorage(); // Get Firebase storage instance
    const [profilePicture, setProfilePicture] = useState(''); // State to hold profile picture URL
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
    const [showAlert, setShowAlert] = useState(false);
    const { t } = useTranslation();  // Utilisation du hook pour les traductions

    const handleReport = () => {
        alert("Moderators have been informed of the report and will act as soon as possible.")
    };

    const [isZoomed, setIsZoomed] = useState(false); // Track zoom status
    const [isMobile, setIsMobile] = useState(false); // Nouvel état pour mobile


    // Fonction pour partager l'image
    const handleShare = async () => {
        await Share.share({
            title: 'Check out this astronomy image!',
            text: 'Here is a cool astronomy image I found on Cosmio!',
            url: window.location.href,
            dialogTitle: 'Share this image',
        });
    };

    const handleCopyUrl = async () => {
        try {
            await navigator.clipboard.writeText(window.location.href);
            alert('URL copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    // Effect to handle modal visibility based on URL parameters
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const imageIdFromUrl = urlParams.get('image');
        if (imageIdFromUrl === imageId) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
        }
    }, [location.search, imageId]);

    // Effect to fetch profile picture of the image author
    useEffect(() => {
        const fetchProfilePicture = async () => {
            if (image && image.author) {
                const picture = await getProfilePicture(image.author);
                setProfilePicture(picture || 'https://example.com/default-profile.jpg');
            }
        };
        fetchProfilePicture();
    }, [image]);

    // Effect to fetch image data from Firestore
    useEffect(() => {
        const fetchImage = async () => {
            if (!imageId) {
                console.error("Image ID is undefined");
                return;
            }
            try {
                const imageRef = doc(db, "images", imageId);
                const imageDoc = await getDoc(imageRef);
                if (imageDoc.exists()) {
                    const imageData = imageDoc.data();
                    setImage({ id: imageDoc.id, ...imageData });
                    setAnnotatedAvailable(!!imageData.annotatedImageUrl);
                } else {
                    console.error("Image not found");
                }
            } catch (error) {
                console.error("Error fetching the image: ", error);
            }
        };

        fetchImage();
    }, [imageId]);

    // Détecter si l'utilisateur est sur un appareil mobile
    useEffect(() => {
        const checkIfMobile = () => {
            const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            setIsMobile(isMobileDevice);
        };
        checkIfMobile();
    }, []);

    // Effect to handle comment input focus event for smooth scrolling
    useEffect(() => {
        const handleFocus = (e) => {
            setTimeout(() => {
                e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 300);
        };

        if (commentInputRef.current) {
            const inputElement = commentInputRef.current;
            inputElement.addEventListener('focus', handleFocus);

            return () => {
                inputElement.removeEventListener('focus', handleFocus);
            };
        }
    }, [commentInputRef.current]);

    if (!image) {
        return <div></div>;
    }

    // Function to toggle annotated image view
    const toggleAnnotated = () => {
        setShowAnnotated(!showAnnotated);
    };

    const imageUrl = showAnnotated
        ? image.annotatedImageUrl
        : image.imageUrl;

    // Function to handle fullscreen image display
    const handleFullscreen = () => {
        if (imageRef.current) {
            if (imageRef.current.requestFullscreen) {
                imageRef.current.requestFullscreen();
            } else if (imageRef.current.mozRequestFullScreen) {
                imageRef.current.mozRequestFullScreen();
            } else if (imageRef.current.webkitRequestFullscreen) {
                imageRef.current.webkitRequestFullscreen();
            } else if (imageRef.current.msRequestFullscreen) {
                imageRef.current.msRequestFullscreen();
            }
        }
    };

    // Function to handle image download
    const handleDownload = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'image.jpg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const timeSincePosted = formatDistanceToNow(image.timestamp.toDate(), { addSuffix: true });

    // Function to navigate to edit page
    const handleEdit = (id) => {
        navigate(`/edit/${id}`);
    };

    // Function to handle image deletion
    const handleDelete = async (id, imageUrl, annotatedImageUrl) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this image?");
        if (confirmDelete) {
            try {
                if (imageUrl) {
                    const storageRef = ref(storage, imageUrl);
                    await deleteObject(storageRef);
                }

                if (annotatedImageUrl) {
                    const annotatedImageRef = ref(storage, annotatedImageUrl);
                    await deleteObject(annotatedImageRef);
                }

                await deleteDoc(doc(db, "images", id));
                navigate(-1);
            } catch (error) {
                console.error("Error deleting the image: ", error);
            }
        }
    };

    // Function to get profile picture of a user
    const getProfilePicture = async (uid) => {
        const docRef = doc(db, 'users', uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return docSnap.data().profilePicture;
        } else {
            console.log('No such document!');
            return null;
        }
    };

    const closeModal = () => {
        navigate('');
    };

    // Functions to handle mouse enter and leave events for annotated image
    const handleMouseEnter = () => {
        if (annotatedAvailable) {
            setShowAnnotated(true);
        }
    };

    const handleMouseLeave = () => {
        setShowAnnotated(false);
    };

    return (
        <Modal disableAnimation="false" className="dark overflow-hidden" isOpen={isModalOpen}>
            <ModalContent>
                <div className="modal-size">

                    <Button className="absolute right-3 top-3 z-40 button-close" isIconOnly variant="light" onPress={closeModal}>
                        <CloseCircle size={24} color="hsl(var(--nextui-default-600))" />
                    </Button>
                    <div className="container-image-preview">
                        <div className="imageContainer w-screen" style={styles.imageContainer}>
                            <div className="max-h-[50vh] md:max-h-full" style={styles.imageWrapper} ref={imageRef}>
                                <div className="flex flex-col justify-between h-full">
                                    {isMobile ? (
                                        <TransformWrapper
                                            onZoomChange={({ scale }) => setIsZoomed(scale > 1)}
                                            minScale={1}
                                            maxScale={5}
                                        >
                                            <TransformComponent>
                                                <img
                                                    src={imageUrl}
                                                    alt={image.name}
                                                    style={{
                                                        ...styles.image,
                                                        position: isZoomed ? 'absolute' : 'relative',
                                                        top: 0,
                                                        left: 0,
                                                        overflow: "visible"
                                                        ,
                                                    }}
                                                />
                                            </TransformComponent>
                                        </TransformWrapper>
                                    ) : (
                                        <img
                                            src={imageUrl}
                                            alt={image.name}
                                            style={styles.image}
                                        />
                                    )}
                                    <div className="absolute flex flex-row p-2 bottom-0 right-0">
                                        <Tooltip className="dark" content={showAnnotated ? t('showoriginal') : t('showannotated')}>
                                            <Switch
                                                className="purple-dark"
                                                checked={showAnnotated}
                                                onChange={toggleAnnotated}
                                                size="md"
                                                startContent={<Image size={16} variant="Bold" />}
                                                endContent={<Scanning size={16} variant="Bold" />}
                                                isDisabled={!annotatedAvailable}
                                            />
                                        </Tooltip>
                                        <div className="button-fullscreen">
                                            <Tooltip className="dark" content="Fullscreen">
                                                <Button variant="light" isIconOnly auto onClick={handleFullscreen}>
                                                    <Maximize3 className="text-foreground" size="24" />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-2" style={styles.infoContainer}>
                            <ScrollShadow>
                                <div className="flex flex-col gap-2 items-start mb-2">
                                    <div className="flex flex-row justify-between w-full">
                                        <div className="cursor-pointer flex gap-2" onClick={() => navigate('/profile/' + image.username)}>
                                            <Avatar
                                                showFallback
                                                src={profilePicture}
                                            />

                                            <div>
                                                <p>
                                                    {image.username}
                                                </p>

                                                <p className="text-default-400" >
                                                    {timeSincePosted}
                                                </p>
                                            </div>



                                        </div>
                                        <div className="flex flex-row items-center">
                                            {image.author === currentUser?.uid ? (
                                                <>
                                                    <Dropdown className="dark">
                                                        <DropdownTrigger>
                                                            <Button isIconOnly variant="light">
                                                                <Tooltip className="purple-dark" content={t("more")}>
                                                                    <More variant="Bold" />
                                                                </Tooltip>
                                                            </Button>
                                                        </DropdownTrigger>
                                                        <DropdownMenu variant="faded">
                                                            <DropdownItem key="edit"
                                                                startContent={<Edit2 size={18} variant="Bold" />}
                                                                onClick={() => handleEdit(imageId)}
                                                            >
                                                                {t("edit")}
                                                            </DropdownItem>
                                                            <DropdownItem key="download"
                                                                startContent={<ImportCurve size={18} variant="Bold" />}
                                                                onClick={() => handleDownload(imageUrl)}
                                                            >
                                                                {t("download")}
                                                            </DropdownItem>
                                                            <DropdownItem key="delete"
                                                                startContent={<Trash size={18} variant="Bold" />}
                                                                onClick={() => handleDelete(imageId, image.imageUrl, image.annotatedImageUrl)}
                                                                color="danger"
                                                                className="text-danger"
                                                            >
                                                                {t("setupManager.delete")}
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </>
                                            ) : (
                                                <div>
                                                    <Tooltip className="dark" content="Report content" >
                                                        <Button onClick={handleReport} color="warning" isIconOnly variant="light" >
                                                            <Flag variant="Bold" size={20} />
                                                        </Button>
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <Divider className="my" />
                                    <div className="flex flex-row gap-2">
                                        <LikeButton likedBy={currentUser?.username}
                                            likedById={currentUser?.uid}
                                            recipientId={image.author}
                                            imageId={image.id}
                                            initialLikes={image.like} />


                                        {isMobile ? (
                                            <Button size="sm" onClick={handleShare} variant="flat">
                                                {t('share')}
                                                <ExportCurve size={20} />
                                            </Button>
                                        ) : (
                                            <Button size="sm" onClick={handleCopyUrl} variant="flat">
                                                {t('share')}
                                                <ExportCurve size={20} />
                                            </Button>
                                        )}
                                    </div>

                                </div>
                                <div className="w-screen" style={styles.chipContainer}>
                                    <div className="flex flex-col gap-4 w-full">
                                        {image.metadata && image.metadata.Description && (
                                            <div>
                                                <p>{image.metadata.Description}</p>

                                            </div>

                                        )}
                                        {/*  
                                        <Tutorial objectName={image.objects} />*/}

                                        <Divider className="my" />
                                        <CommentSection imageId={image.id} imageOwnerId={image.author} inputRef={commentInputRef} />
                                        <Divider className="my" />
                                        {image.metadata && image.metadata.LocationName && (
                                            <div>
                                                <h2 style={styles.metadataGroupName}> {t('location')}</h2>
                                                <p>{image.metadata.LocationName}</p>
                                            </div>
                                        )}
                                        {image.metadata && image.metadata.ObservationDate && (
                                            <div>
                                                <h2 style={styles.metadataGroupName}> {t('observationdate')}</h2>
                                                <p>{image.metadata.ObservationDate.toDate().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                            </div>
                                        )}
                                        <div className="w-full overflow-auto flex flex-col gap-4">
                                            {image.objects && image.objects.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('object')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.objects.map((obj, objIndex) => (
                                                            <Link
                                                                key={objIndex}
                                                                to={`/search?tags=${encodeURIComponent(obj)}`}
                                                                className="purple-dark"
                                                            >
                                                                <CustomChip onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                                    <div className="flex flex-row gap-1 min-w-0 overflow-hidden">
                                                                        {obj}
                                                                        {/* 
                                                                        <AstronomicalEvent objectName={obj} />
                                                                        */}
                                                                    </div>
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Telescope && image.metadata.Telescope.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('telescope')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Telescope.map((obj, objIndex) => (
                                                            <Link
                                                                key={objIndex}
                                                                to={`/search?tags=${encodeURIComponent(obj)}`}
                                                            >
                                                                <CustomChip>
                                                                    {obj}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Mount && image.metadata.Mount.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('mount')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Mount.map((filter, filterIndex) => (
                                                            <Link
                                                                key={filterIndex}
                                                                to={`/search?tags=${encodeURIComponent(filter)}`}
                                                            >
                                                                <CustomChip>
                                                                    {filter}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Camera && image.metadata.Camera.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('camera')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Camera.map((filter, filterIndex) => (
                                                            <Link
                                                                key={filterIndex}
                                                                to={`/search?tags=${encodeURIComponent(filter)}`}
                                                            >
                                                                <CustomChip>
                                                                    {filter}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Filter && image.metadata.Filter.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('filter')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Filter.map((filter, filterIndex) => (
                                                            <Link
                                                                key={filterIndex}
                                                                to={`/search?tags=${encodeURIComponent(filter)}`}
                                                            >
                                                                <CustomChip>
                                                                    {filter}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Software && image.metadata.Software.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('software')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Software.map((filter, filterIndex) => (
                                                            <Link
                                                                key={filterIndex}
                                                                to={`/search?tags=${encodeURIComponent(filter)}`}
                                                            >
                                                                <CustomChip>
                                                                    {filter}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                            {image.metadata && image.metadata.Accessory && image.metadata.Accessory.length > 0 && (
                                                <div>
                                                    <h2 style={styles.metadataGroupName}>{t('accessory')}</h2>
                                                    <div className="flex flex-row flex-wrap w-full">
                                                        {image.metadata.Accessory.map((filter, filterIndex) => (
                                                            <Link
                                                                key={filterIndex}
                                                                to={`/search?tags=${encodeURIComponent(filter)}`}
                                                            >
                                                                <CustomChip>
                                                                    {filter}
                                                                </CustomChip>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </ScrollShadow>
                        </div>
                    </div>
                </div>
            </ModalContent>
        </Modal>
    );
};

const styles = {
    imageContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: '8px',
        justifyContent: "center",
    },
    imageWrapper: {
        backgroundColor: "black",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        borderRadius: "8px",
        overflow: "hidden",
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    infoContainer: {
        flex: 1,
        display: "flex",
        height: "auto",
        flexDirection: "column",
        overflow: "auto",
        height: "100%",
    },
    chipContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "10px",
        flexDirection: "column",
        width: "100%",
    },
    metadataGroupName: {
        fontSize: '12px',
        color: 'hsl(var(--nextui-default-500))',
        textTransform: "uppercase",
        fontWeight: '500',
        marginBottom: '0.2rem',
    }
};

export default ImageDetails;