import React, { useState, useEffect } from "react";
import { Input, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Checkbox, Link } from "@nextui-org/react";
import { useDisclosure } from "@nextui-org/react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "./Firebase/firebaseConfig";
import { collection, query, where, getDocs, setDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom"; // Import de useNavigate depuis react-router-dom
import Feature1 from "../assets/images/Feature1.png"; // Import the default SVG
import Feature2 from "../assets/images/Feature2.png"; // Import the second feature image
import Feature3 from "../assets/images/Feature3.png"; // Import the third feature image
import { CloseCircle, TickCircle } from "iconsax-react"; // Import de l'icône de cœur
import { usePagination, PaginationItemType } from "@nextui-org/react";
import { useTranslation } from "react-i18next"; // Import i18next


const Signup = ({ onSignup }) => {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false); // State pour gérer l'état de chargement
    const [isSuccessModalOpen, setSuccessModalOpen] = useState(false); // State pour gérer l'ouverture de la modal de succès
    const [isSummaryModalOpen, setSummaryModalOpen] = useState(false); // State pour gérer l'ouverture de la modal de succès
    const [usernameError, setUsernameError] = useState(""); // State pour gérer les erreurs de nom d'utilisateur
    const { isOpen, onOpen, onClose } = useDisclosure(); // Utilisation de onOpen et onClose pour la modal d'erreur
    const [isTermsAccepted, setTermsAccepted] = useState(false); // State pour vérifier si les termes sont acceptés
    const navigate = useNavigate(); // Obtenir la fonction de navigation
    const { t } = useTranslation(); // Hook pour récupérer les traductions

    let timeout; // Variable pour gérer le délai avant de vérifier le nom d'utilisateur

    const checkUsernameAvailability = async (username) => {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("username", "==", username));
        const snapshot = await getDocs(q);
        return snapshot.empty; // true si aucun document trouvé, sinon false
    };

    const handleSignup = async () => {
        setError("");
        setIsLoading(true); // Début du chargement

        // Vérifier si les mots de passe correspondent
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            onOpen();
            setIsLoading(false); // Fin du chargement en cas d'erreur
            return;
        }

        // Vérifier si le username est disponible
        let isUsernameAvailable;
        try {
            isUsernameAvailable = await checkUsernameAvailability(username);
        } catch (error) {
            console.error("Error checking username availability:", error);
            setError("Error checking username availability.");
            onOpen();
            setIsLoading(false); // Fin du chargement en cas d'erreur
            return;
        }

        if (!isUsernameAvailable) {
            setError("Username is already taken.");
            onOpen();
            setIsLoading(false); // Fin du chargement en cas d'erreur
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            // Ajouter l'utilisateur à la collection Firestore avec le username et l'acceptation des termes
            await setDoc(doc(db, "users", userCredential.user.uid), {
                email: email,
                username: username,
                termsAccepted: true,
                termsAcceptedAt: new Date() // Stocke la date d'acceptation des termes
            });
            console.log("User signed up:", userCredential.user);
            onSignup(); // Appeler la fonction de rappel fournie après l'inscription réussie
            setSuccessModalOpen(true); // Ouvrir la modal de succès après inscription réussie
        } catch (error) {
            console.error("Error signing up:", error);
            setError("Failed to sign up. Please try again later.");
            onOpen(); // Ouvrir le modal d'erreur
        } finally {
            setIsLoading(false); // Fin du chargement après le processus d'inscription
        }
    };

    useEffect(() => {
        clearTimeout(timeout); // Effacer le délai précédent
        timeout = setTimeout(async () => {
            try {
                const isAvailable = await checkUsernameAvailability(username);
                if (!isAvailable) {
                    setUsernameError("Username is already taken.");
                } else {
                    setUsernameError("");
                }
            } catch (error) {
                console.error("Error checking username availability:", error);
                setUsernameError("Error checking username availability.");
            }
        }, 300); // Délai de 300ms avant de vérifier le nom d'utilisateur

        // Cleanup function for useEffect
        return () => clearTimeout(timeout);
    }, [username]); // Effect will run whenever `username` changes

    const { activePage, range, setPage } = usePagination({
        total: 3, // Nombre total de features
        showControls: false, // Ne pas afficher les boutons de contrôle
        siblings: 1,
        boundaries: 1,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            setPage((prevPage) => (prevPage < 3 ? prevPage + 1 : 1));
        }, 5000); // Changer de page toutes les 5 secondes

        return () => clearInterval(interval); // Nettoyer l'intervalle à la désinstallation du composant
    }, []);

    const features = [
        { image: Feature1, position: 'left calc(90% - 0px) bottom calc(100% - 50px)', size: '170%', name: t('features.feature1') },
        { image: Feature2, position: 'left calc(100% + 60px) bottom calc(100% - 50px)', size: '120%', name: t('features.feature2') },
        { image: Feature3, position: 'left calc(100% + 50px) bottom calc(100% - 50px)', size: '135%', name: t('features.feature3') },
        // Ajoutez d'autres features ici
    ];
    const isFormValid = email && username && password && confirmPassword && isTermsAccepted;

    return (
        <div className="min-h-screen">
            <div className="flex mt-20 justify-between gap-16">
                <div className="flex flex-col gap-3">
                    <h1 className="text-2xl font-bold mb-4">{t('signup.title')}</h1>
                    <Input
                        clearable
                        underlined
                        label={t('signup.emailLabel')}
                        placeholder={t('signup.emailPlaceholder')}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        size="lg"
                    />

                    <Input
                        clearable
                        underlined
                        label={t('signup.usernameLabel')}
                        placeholder={t('signup.usernamePlaceholder')}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        error={usernameError ? usernameError : undefined}
                        size="lg"
                    />

                    <Input
                        clearable
                        underlined
                        label={t('signup.passwordLabel')}
                        placeholder={t('signup.passwordPlaceholder')}
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        size="lg"
                    />

                    <Input
                        clearable
                        underlined
                        label={t('signup.confirmPasswordLabel')}
                        placeholder={t('signup.confirmPasswordPlaceholder')}
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        size="lg"
                    />

                    <div>
                        <Checkbox
                            isSelected={isTermsAccepted}
                            onChange={() => setTermsAccepted(!isTermsAccepted)}
                        >
                            {t('signup.termsText')}
                            <Link size="sm" className="text-foreground underline" href="/privacy-policy" target="_blank">
                                {t('signup.privacyPolicy')}
                            </Link>,
                            <Link size="sm" className="text-foreground underline" href="/terms-of-service" target="_blank">
                                {t('signup.termsOfService')}
                            </Link>,
                            <Link size="sm" className="text-foreground underline" href="/eula" target="_blank">
                                {t('signup.eula')}
                            </Link>.
                        </Checkbox>

                        <Link size="sm" className="cursor-pointer underline text-primary-50" onClick={() => setSummaryModalOpen(true)} variant="flat">
                            {t('signup.readSummary')}
                        </Link>
                    </div>

                    <Button size="lg" color="primary" onPress={handleSignup} isLoading={isLoading} isDisabled={!isFormValid}>
                        {t('signup.signupButton')}
                    </Button>
                </div>

                <div
                    className="div-feature flex-col bg-primary-400 rounded-xl p-4 w-2/3 h-auto border border-1 border-stone-900	 "
                    style={{
                        backgroundImage: `url(${features[activePage - 1].image})`,
                        backgroundSize: features[activePage - 1].size,
                        backgroundPosition: features[activePage - 1].position,
                        backgroundRepeat: 'no-repeat',
                        position: 'relative',
                    }}
                >
                    <div>
                        <p className="text-xl font-medium mb-4">{features[activePage - 1].name}</p>
                    </div>

                    <div className="absolute -bottom-5 left-1/2">
                        <ul className="flex gap-2 items-center">
                            {range.map((page) => {
                                if (page === PaginationItemType.DOTS) {
                                    return (
                                        <li key={page} className="w-3 h-3">
                                            ...
                                        </li>
                                    );
                                }
                                return (
                                    <li key={page} aria-label={`page ${page}`} className="w-3 h-3">
                                        <button
                                            className={`w-full h-full bg-default-300 rounded-full ${activePage === page && "bg-default-900"}`}
                                            onClick={() => setPage(page)}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>



            {/* Modal de succès */}
            <Modal isDismissable={false} hideCloseButton placement="center" isOpen={isSuccessModalOpen} onOpenChange={() => setSuccessModalOpen(false)} className="purple-dark text-foreground text-center">
                <ModalContent>
                    <TickCircle color="#6ADFBC" className="text-foreground m-auto mt-8" variant="Bulk" size="80" />
                    <ModalHeader className="m-auto mt-0">
                        {t('accountCreatedTitle')} {/* Utilisation de t() pour traduire le titre */}
                    </ModalHeader>
                    <ModalBody>
                        <p>{t('accountCreatedMessage')}</p> {/* Utilisation de t() pour traduire le message */}
                    </ModalBody>
                    <ModalFooter>
                        <Button className="w-full" color="primary" onPress={() => {
                            setSuccessModalOpen(false);
                            navigate("/profile");
                        }}>
                            {t('goToProfileButton')} {/* Utilisation de t() pour traduire le texte du bouton */}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Modal d'erreur */}
            <Modal isDismissable={false} hideCloseButton placement="center" isOpen={isOpen} onOpenChange={onClose} className="purple-dark text-foreground text-center">
                <ModalContent>
                    <CloseCircle color="#FF697B" className="text-foreground m-auto mt-8" variant="Bulk" size="80" />
                    <ModalHeader className="m-auto mt-0">Sign Up Error</ModalHeader>
                    <ModalBody>
                        <p>{error}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="w-full" color="primary" onPress={onClose}>
                            Ok
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Modal de succès */}
            <Modal placement="center" isOpen={isSummaryModalOpen} onOpenChange={() => setSummaryModalOpen(false)} className="purple-dark text-foreground">
                <ModalContent>
                    <ModalHeader>
                        {t('summaryModalTitle')}
                    </ModalHeader>
                    <ModalBody>
                        <p>{t('summaryItems.copyright')}</p> {/* Utilisation de t() pour traduire le texte */}
                        <p>{t('summaryItems.thirdParties')}</p> {/* Utilisation de t() pour traduire le texte */}
                        <p>{t('summaryItems.dataStorage')}</p> {/* Utilisation de t() pour traduire le texte */}
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    );
};

export default Signup;
