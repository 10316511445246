import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import { db } from "../components/Firebase/firebaseConfig";
import { Textarea, Button, Tabs, Tab, Skeleton, DatePicker } from "@nextui-org/react";
import CreatableAsyncSelect from 'react-select/async-creatable';
import { components } from 'react-select';
import UserContext from "../components/UserContext";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { ClipboardText, Microscope, TickCircle } from "iconsax-react";
import { useTranslation } from 'react-i18next';

const libraries = ["places"];

const CustomInput = (props) => {
    const { selectProps, ...rest } = props;
    return (
        <>
            {selectProps.isProcessing ? (
                <Skeleton className="w-full h-10" />
            ) : (
                <components.Input {...rest} />
            )}
        </>
    );
};

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-100))',
        '&:hover': {
            borderColor: 'transparent',
        },
        borderColor: state.isFocused ? 'transparent' : 'transparent',
        borderRadius: '12px',
        height: 'fit-content',
        minHeight: '44px',
        boxShadow: state.isFocused ? 'transparent' : 'transparent',
        cursor: state.isFocused ? 'p' : 'pointer',
        display: 'flex',
        alignItems: 'center',
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-100))',
        color: 'hsl(var(--nextui-default-900))',
        borderRadius: '8px',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? 'hsl(var(--nextui-default-200))'
            : 'hsl(var(--nextui-default-100))',
        color: 'hsl(var(--nextui-default-900))',
        fontSize: '0.875rem',
        cursor: 'pointer',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-300))',
        borderRadius: '24px',
        paddingInlineStart: '8px',
        paddingBlock: '2px',
        color: 'hsl(var(--nextui-default-900))',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
        paddingInline: '6px',
        marginInlineStart: '8px',
        ':hover': {
            backgroundColor: 'hsl(var(--nextui-default-900))',
            color: 'hsl(var(--nextui-default-200))',
        },
        borderRadius: '24px',
    }),
    input: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
        fontSize: '0.875rem',
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '0.875rem',
        color: 'hsl(var(--nextui-default-500))',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
};

const EditImageDetails = () => {
    const { imageId } = useParams();
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const { isLoaded } = useLoadScript({ googleMapsApiKey: "AIzaSyBOxGeBmlLVcDXCh6O-RmteNwwXwEDXqyo", libraries });

    const [imageData, setImageData] = useState(null);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(true);
    const [objects, setObjects] = useState([]);
    const [realtimeObjects, setRealtimeObjects] = useState([]);
    const [location, setLocation] = useState({});
    const [locationName, setLocationName] = useState("");

    const [processingState, setProcessingState] = useState(false);
    const [descriptionError, setDescriptionError] = useState("");
    const [isImageLoading, setIsImageLoading] = useState(false);
    const { t } = useTranslation();

    const [metadata, setMetadata] = useState({
        Object: [],
        Telescope: [],
        Camera: [],
        Software: [],
        Accessory: [],
        Filter: [],
        Mount: [],
        Location: "",
        Description: "",
        ObservationDate: []
    });



    const [defaultTelescopeOptions, setDefaultTelescopeOptions] = useState([]);
    const [defaultCameraOptions, setDefaultCameraOptions] = useState([]);
    const [defaultSoftwareOptions, setDefaultSoftwareOptions] = useState([]);
    const [defaultFilterOptions, setDefaultFilterOptions] = useState([]);
    const [defaultAccessoryOptions, setDefaultAccessoryOptions] = useState([]);
    const [defaultMountOptions, setDefaultMountOptions] = useState([]);
    const [objectsOptions, setObjectsOptions] = useState([]);
    const DESCRIPTION_LIMIT = 300;

    const [searchBox, setSearchBox] = useState(null);
    const [place, setPlace] = useState(null);


    console.log("Date:", metadata.ObservationDate);



    useEffect(() => {
        if (currentUser) {
            fetchData();
            fetchDefaultOptions();
        }
    }, [currentUser, imageId]);


    const fetchData = async () => {
        const imageRef = doc(db, "images", imageId);
        const imageDoc = await getDoc(imageRef);
        if (imageDoc.exists()) {
            const data = imageDoc.data();
            if (data.author !== currentUser.uid) {
                navigate(`/?image=${imageId}`);
                return;
            }

            setImageData(data);
            setName(data.name || "");
            setDescription(data.metadata?.Description || "");
            setMetadata(formatMetadata(data.metadata || {}));
            setObjects(data.objects ? data.objects.map(obj => ({ value: obj, label: obj })) : []);
            setLocation(data.metadata?.Location || {});
            setLocationName(data.metadata?.LocationName || "");
            setLoading(false);
        } else {
            console.error("Image not found");
        }

        const imageDocRef = doc(db, "images", imageId);
        const unsubscribe = onSnapshot(imageDocRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                if (data.objects) {
                    setRealtimeObjects(data.objects);
                }
                setProcessingState(data.state === "processing");
            }
        });
        return () => unsubscribe();
    };


    const fetchDefaultOptions = async () => {
        await Promise.all([
            fetchCollectionOptions('telescope', setDefaultTelescopeOptions),
            fetchCollectionOptions('camera', setDefaultCameraOptions),
            fetchCollectionOptions('software', setDefaultSoftwareOptions),
            fetchCollectionOptions('filter', setDefaultFilterOptions),
            fetchCollectionOptions('accessory', setDefaultAccessoryOptions),
            fetchCollectionOptions('mount', setDefaultMountOptions),
            fetchObjectValues()
        ]);
    };

    const fetchCollectionOptions = async (collectionName, setOptions) => {
        const docRef = doc(db, 'system', collectionName);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data[collectionName]) {
                const options = data[collectionName].map(value => ({
                    value: value,
                    label: value
                })).slice(0, 10); // Limiter le nombre de résultats à 10
                setOptions(options);
            } else {
                console.log(`No such field ${collectionName} in document ${collectionName}!`);
            }
        } else {
            console.log(`No such document for ${collectionName}!`);
        }
    };


    const fetchObjectValues = async () => {
        const docRef = doc(db, 'system', 'object');
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const options = data.object.map(value => ({
                value: value,
                label: value
            })).slice(0, 10); // Limiter le nombre de résultats à 10
            setObjectsOptions(options);
        } else {
            console.log('No such document for object!');
        }
    };

    const formatMetadata = (metadata) => {
        const formatted = {};
        for (let key in metadata) {
            if (Array.isArray(metadata[key])) {
                formatted[key] = metadata[key].map(item => ({
                    value: item,
                    label: item
                }));
            } else {
                formatted[key] = metadata[key];
            }
        }
        return formatted;
    };


    const handleSave = async () => {
        if (imageData) {
            const imageRef = doc(db, "images", imageId);

            // Construire l'objet metadata en combinant les nouvelles valeurs et les anciennes valeurs
            const updatedMetadata = {
                ...metadata,
                Description: description,
                LocationName: locationName
            };

            if (location && location.lat !== undefined && location.lng !== undefined) {
                updatedMetadata.Location = location;
            }

            // Mettre à jour Firestore avec le nouvel objet metadata
            await updateDoc(imageRef, {
                name: name,
                objects: objects.map(obj => obj.value),
                metadata: Object.keys(updatedMetadata).reduce((acc, key) => {
                    acc[key] = Array.isArray(updatedMetadata[key]) ? updatedMetadata[key].map(item => item.value || item) : updatedMetadata[key];
                    return acc;
                }, {})
            });

            navigate(`/?image=${imageId}`);
        }
    };





    const loadOptions = async (collectionName, inputValue, callback) => {
        const docRef = doc(db, 'system', collectionName);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data[collectionName]) {
                const options = data[collectionName]
                    .filter(value => value.toLowerCase().includes(inputValue.toLowerCase()))
                    .slice(0, 10) // Limiter le nombre de résultats à 10
                    .map(value => ({
                        value: value,
                        label: value
                    }));
                callback(options);
            } else {
                console.log(`No such field ${collectionName} in document ${collectionName}!`);
                callback([]);
            }
        } else {
            console.log(`No such document for ${collectionName}!`);
            callback([]);
        }
    };


    // Utilisation de la fonction générique pour charger des options
    const loadObjectOptions = (inputValue, callback) => loadOptions('object', inputValue, callback);
    const loadTelescopeOptions = (inputValue, callback) => loadOptions('telescope', inputValue, callback);
    const loadCameraOptions = (inputValue, callback) => loadOptions('camera', inputValue, callback);
    const loadSoftwareOptions = (inputValue, callback) => loadOptions('software', inputValue, callback);
    const loadFilterOptions = (inputValue, callback) => loadOptions('filter', inputValue, callback);
    const loadMountOptions = (inputValue, callback) => loadOptions('mount', inputValue, callback);
    const loadAccessoryOptions = (inputValue, callback) => loadOptions('accessory', inputValue, callback);

    const metadataOptions = {
        Object: objectsOptions,
        Telescope: defaultTelescopeOptions,
        Camera: defaultCameraOptions,
        Software: defaultSoftwareOptions,
        Accessory: defaultAccessoryOptions,
        Filter: defaultFilterOptions,
        Mount: defaultMountOptions,
        Location: [],
        Description: []
    };

    const onLoad = ref => setSearchBox(ref);

    const onPlacesChanged = () => {
        const places = searchBox.getPlaces();
        const place = places[0];
        if (place) {
            setLocation(place.geometry.location.toJSON());
            setLocationName(place.formatted_address);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }




    return (
        <>
            {currentUser ? (
                <>
                    <h1 className="text-3xl font-bold mb-4">{t("editimagetitle")}</h1>
                    <div className="h-fit container-image-preview ">
                        <div className="image-preview w-full basis-1/2 ">
                            {isImageLoading ? (
                                <Skeleton className="bg-default-100 rounded-lg">
                                    <div className="h-[80vh] rounded-lg"></div>
                                </Skeleton>
                            ) : (
                                <img src={imageData.imageUrl} alt="Uploaded" className="responsive-img" />
                            )}
                        </div>
                        <div className="flex flex-col w-full basis-1/2 justify-between ">

                            <div>
                                <Tabs
                                    variant="underlined"
                                    className="dark "
                                    classNames={{
                                        tabContent: "group-data-[selected=true]:text-[hsl(var(--text-foreground))] ",
                                    }}
                                >
                                    <Tab
                                        key="general"
                                        title={
                                            <div className="flex items-center space-x-1">
                                                <ClipboardText size={18} variant="Bold" />
                                                <span>{t("general")}</span>
                                            </div>
                                        }

                                    >
                                        <div className="tab-content">
                                            <div>
                                                <label className=" label-async">          <span>{t("object")}</span></label>
                                                <CreatableAsyncSelect
                                                    isMulti
                                                    cacheOptions
                                                    loadOptions={loadObjectOptions}
                                                    defaultOptions={objectsOptions}
                                                    value={objects}
                                                    onChange={(selectedOptions) => {
                                                        setObjects(selectedOptions || []);
                                                    }}
                                                    placeholder={processingState ? "" : "Enter Object"}
                                                    styles={customStyles}
                                                    components={{ Input: (inputProps) => <CustomInput {...inputProps} processingState={processingState} /> }}
                                                    processingState={processingState}
                                                />
                                            </div>
                                            <div>
                                                <label className="label-async">          <span>{t("description")}</span></label>
                                                <Textarea
                                                    className="dark"
                                                    placeholder="Enter Description"
                                                    value={description}
                                                    isInvalid={!!descriptionError}
                                                    errorMessage={descriptionError}
                                                    onChange={(e) => {
                                                        const { value } = e.target;
                                                        if (value.length <= DESCRIPTION_LIMIT) {
                                                            setDescription(value);
                                                            setMetadata({ ...metadata, Description: value });
                                                            setDescriptionError("");
                                                        } else {
                                                            setDescriptionError(`Description cannot exceed ${DESCRIPTION_LIMIT} characters`);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label className="label-async">  <span>{t("location")}</span></label>
                                                {isLoaded && (
                                                    <StandaloneSearchBox
                                                        onLoad={onLoad}
                                                        onPlacesChanged={onPlacesChanged}
                                                    >
                                                        <input
                                                            type="text"
                                                            placeholder="Enter a location"
                                                            className="location-input"
                                                            value={locationName}
                                                            onChange={(e) => setLocationName(e.target.value)}
                                                        />
                                                    </StandaloneSearchBox>
                                                )}
                                            </div>

                                            <div>
                                                <label className="label-async"><span>{t("observationdate")}</span> (mm/dd/yyyy)</label>
                                                <DatePicker

                                                    className='dark'
                                                    onChange={(date) => {
                                                        const jsDate = new Date(date.year, date.month - 1, date.day);
                                                        setMetadata({ ...metadata, ObservationDate: jsDate });

                                                    }}

                                                />
                                            </div>


                                        </div>
                                    </Tab>
                                    <Tab
                                        title={
                                            <div className="flex items-center space-x-1">
                                                <Microscope size={18} variant="Bold" />
                                                <span> {t("equipment")}</span>
                                            </div>
                                        }
                                        key="equipment"
                                    >
                                        <div className="tab-content">
                                            {["Telescope", "Camera", "Mount", "Accessory", "Software", "Filter"].map(key => (
                                                <div key={key}>
                                                    <label className="label-async">{key}</label>
                                                    <CreatableAsyncSelect
                                                        isMulti
                                                        cacheOptions
                                                        loadOptions={(inputValue, callback) => {
                                                            switch (key) {
                                                                case "Telescope":
                                                                    return loadTelescopeOptions(inputValue, callback);
                                                                case "Camera":
                                                                    return loadCameraOptions(inputValue, callback);
                                                                case "Mount":
                                                                    return loadMountOptions(inputValue, callback);
                                                                case "Accessory":
                                                                    return loadAccessoryOptions(inputValue, callback);
                                                                case "Software":
                                                                    return loadSoftwareOptions(inputValue, callback);
                                                                case "Filter":
                                                                    return loadFilterOptions(inputValue, callback);
                                                                default:
                                                                    return;
                                                            }
                                                        }}
                                                        defaultOptions={metadataOptions[key]}
                                                        value={metadata[key]}
                                                        onChange={(selectedOptions) => setMetadata({ ...metadata, [key]: selectedOptions || [] })}
                                                        placeholder={`Enter ${key}`}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </Tab>

                                </Tabs>
                            </div>


                            <div className="flex flex-row gap-2 w-full justify-end">
                                <Button className="w-full" onClick={() => navigate(-1)}>{t("cancel")}</Button>
                                <Button startContent={<TickCircle size={22} variant="linear" />} className="w-full" color="primary" onClick={handleSave}>{t("setupManager.save")}</Button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <p>Please log in to edit and view images.</p>
            )}

            <style jsx>{`
                .image-preview {
                    display: flex;
                    border-radius: 8px;
                    height: 80vh;
                    border: 1px solid hsl(var(--nextui-default-100));
                    flex-direction: column;
                }

                .responsive-img {
                    width: auto;
                    height: auto;
                    margin: auto;
                    max-height: 80vh;
                }

                .location-input {
                    width: 100%;
                    border-radius: 8px;
                    padding: 10px;
                    background-color: hsl(var(--nextui-default-100));
                    font-size: 0.875rem;
                    color: hsl(var(--nextui-default-900));
                    height: 44px;
                }

                .label-async {
                    color: hsl(var(--nextui-default-400));
                    font-size: 14px;
                    font-weight: 500;
                }

                .tab-content {
           
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    border-radius: 8px;
                    font-family: 'Mona Sans';
                    font-weight: 500;
                    height:100%;
                }
            `}</style>
        </>
    );
};

export default EditImageDetails;
