import React from 'react';
import { Accordion, AccordionItem } from '@nextui-org/react';

const FAQ = () => {
    return (
        <>
            <h1 className='text-3xl font-bold mb-4'>Find answers to your questions</h1>
            <Accordion variant='splitted'>
                <AccordionItem title="What personal information do you collect?">
                    <p>We collect the following personal information from you:</p>
                    <ul>
                        <li>Email address</li>
                        <li>Username</li>
                        <li>Photos you upload on our platform</li>
                    </ul>
                </AccordionItem>
                <AccordionItem title="Do I retain ownership of my posted images?">
                    <p>Yes, you retain all copyrights to your images. However, by posting them on our platform, you grant us a non-exclusive license to use, reproduce, distribute, and display your images on our platform and social media, as well as in the context of citizen science projects. This helps us to share your contributions with the community.</p>
                </AccordionItem>
                <AccordionItem title="Where are the photos stored and processed?">
                    <p>Your photos are stored on Firebase servers, which are known for their security and reliability. Additionally, we use the Astrometry API to process these photos and provide astronomical data and analysis, ensuring your photos are protected and handled responsibly.</p>
                </AccordionItem>
                <AccordionItem title="Under what circumstances do we share your information?">
                    <p>We may share your information under these circumstances:</p>
                    <ul>
                        <li>With your consent: When you have given us specific permission to do so.</li>
                        <li>For legal reasons: To comply with laws, governmental requests, judicial proceedings, court orders, or legal processes.</li>
                        <li>For business purposes: To fulfill our legitimate business interests or contractual obligations.</li>
                    </ul>
                </AccordionItem>
                <AccordionItem title="How do we keep your information safe?">
                    <p>We have implemented various security measures to protect your personal information. These include technical and organizational safeguards designed to prevent unauthorized access, disclosure, or misuse of your data. However, no method of internet transmission or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                </AccordionItem>
                <AccordionItem title="What activities are prohibited on the site?">
                    <p>You must not engage in any of the following activities:</p>
                    <ul>
                        <li>Copying, distributing, or disclosing any part of the website without permission.</li>
                        <li>Using automated systems like robots or spiders to access the website.</li>
                        <li>Transmitting spam or other unsolicited communications.</li>
                        <li>Attempting to interfere with or compromise the site's security.</li>
                        <li>Uploading harmful software such as viruses or worms.</li>
                        <li>Harvesting personal information from the site.</li>
                        <li>Publishing AI-generated photos.</li>
                        <li>Posting harmful, abusive, or discriminatory content.</li>
                    </ul>
                </AccordionItem>
                <AccordionItem title="What are your privacy rights?">
                    <p>Depending on your location, you may have the following privacy rights:</p>
                    <ul>
                        <li>Access: You can request access to your personal information.</li>
                        <li>Rectification: You can ask us to correct any inaccurate or incomplete data.</li>
                        <li>Erasure: You can request the deletion of your personal information.</li>
                        <li>Restriction: You can ask us to restrict the processing of your data.</li>
                        <li>Objection: You can object to the processing of your data in certain circumstances.</li>
                        <li>Portability: You can request to receive your data in a structured, commonly used format.</li>
                    </ul>
                </AccordionItem>
                <AccordionItem title="What third-party services are used?">
                    <p>We use the following third-party services to enhance our platform:</p>
                    <ul>
                        <li>Firebase Google: Provides backend services and data storage.</li>
                        <li>Astrometry Nova API: Offers astronomical data and analysis services.</li>
                        <li>Aptabase: Supplies analytics services to help us understand user behavior.</li>
                    </ul>
                </AccordionItem>
                <AccordionItem title="What are your responsibilities as a user?">
                    <p>As a user, you are responsible for:</p>
                    <ul>
                        <li>Maintaining the confidentiality of your account login information.</li>
                        <li>Informing us immediately of any unauthorized use of your account or security breaches.</li>
                        <li>Using the site in compliance with our terms and conditions.</li>
                    </ul>
                </AccordionItem>

            </Accordion>
        </>
    );
};

export default FAQ;
