import React from 'react';

const EULA = ({ companyName, companyEmail }) => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-4">End User License Agreement (EULA)</h1>
            <p className="mb-4">Effective Date: June 29, 2024</p>

            <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
            <p className="mb-4">
                This End User License Agreement (EULA) is a legal agreement between you ("User" or "You") and {companyName} ("We" or "Our") for the use of the {companyName} application ("Application"). By downloading, installing, or using the Application, you agree to be bound by the terms and conditions of this EULA.
            </p>

            <h2 className="text-2xl font-bold mb-4">2. License</h2>
            <p className="mb-4">
                Subject to the terms and conditions of this EULA, we grant you a non-exclusive, non-transferable, and revocable license to use the Application for personal and non-commercial purposes.
            </p>

            <h2 className="text-2xl font-bold mb-4">3. Restrictions</h2>
            <p className="mb-4">
                You agree not to:
            </p>
            <ul className="mb-4 list-disc list-inside">
                <li>Modify, decompile, disassemble, or reverse engineer the Application.</li>
                <li>Distribute, rent, sub-license, loan, or transfer the Application to any third party.</li>
                <li>Use the Application for any illegal or unauthorized purpose.</li>
                <li>Interfere with the operation of the Application or its associated servers.</li>
            </ul>

            <h2 className="text-2xl font-bold mb-4">4. Intellectual Property</h2>
            <p className="mb-4">
                The Application and all related intellectual property rights are the exclusive property of {companyName} or its licensors. You do not acquire any ownership rights in the Application or its contents.
            </p>

            <h2 className="text-2xl font-bold mb-4">5. Updates</h2>
            <p className="mb-4">
                We may, from time to time, provide updates, patches, or improvements to the Application. You agree that these updates will be governed by this EULA, unless otherwise stated.
            </p>

            <h2 className="text-2xl font-bold mb-4">6. Privacy</h2>
            <p className="mb-4">
                Your use of the Application is also governed by our Privacy Policy, which is incorporated by reference into this EULA. Please read our Privacy Policy to understand how we collect, use, and share your personal information.
            </p>

            <h2 className="text-2xl font-bold mb-4">7. Prohibited Content and Behavior</h2>
            <p className="mb-4">
                You agree not to post, upload, or share any content that is:
            </p>
            <ul className="mb-4 list-disc list-inside">
                <li>Objectionable, including but not limited to content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.</li>
                <li>Discriminatory or promotes discrimination, including but not limited to discrimination based on race, ethnicity, national origin, religion, gender, gender identity, sexual orientation, disability, or age.</li>
                <li>Infringing on any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.</li>
                <li>Violating the legal rights (including the rights of publicity and privacy) of others or containing any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with this EULA and our Privacy Policy.</li>
            </ul>
            <p className="mb-4">
                We reserve the right to remove any content that violates these terms or is otherwise deemed inappropriate at our sole discretion. We also reserve the right to ban users who violate these terms or engage in inappropriate behavior.
            </p>

            <h2 className="text-2xl font-bold mb-4">8. Content Moderation</h2>
            <p className="mb-4">
                We use Perspective API to moderate textual content on the Application. Perspective API helps us identify and remove objectionable content, such as harassment, threats, and hate speech. By using the Application, you consent to the use of Perspective API for content moderation.
            </p>

            <h2 className="text-2xl font-bold mb-4">9. Reporting Objectionable Content</h2>
            <p className="mb-4">
                If you encounter any objectionable content on the Application, please report it to us immediately. We will act on objectionable content reports within 24 hours by removing the content and ejecting the user who provided the offending content.
            </p>

            <h2 className="text-2xl font-bold mb-4">10. Limitation of Liability</h2>
            <p className="mb-4">
                To the maximum extent permitted by applicable law, in no event shall {companyName}, its affiliates, agents, directors, employees, suppliers, or licensors be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Application; (ii) any conduct or content of any third party on the Application; (iii) any content obtained from the Application; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
            </p>

            <h2 className="text-2xl font-bold mb-4">11. Indemnification</h2>
            <p className="mb-4">
                You agree to indemnify, defend, and hold harmless {companyName}, its affiliates, agents, directors, employees, suppliers, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) incurred directly or indirectly as a result of your use of the Application or your violation of this EULA.
            </p>

            <h2 className="text-2xl font-bold mb-4">12. Termination</h2>
            <p className="mb-4">
                We may terminate or suspend your access to the Application immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the terms.
            </p>

            <h2 className="text-2xl font-bold mb-4">13. Governing Law</h2>
            <p className="mb-4">
                This EULA shall be governed and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions.
            </p>

            <h2 className="text-2xl font-bold mb-4">14. Contact Us</h2>
            <p className="mb-4">
                If you have any questions about this EULA, please contact us at {companyEmail}.
            </p>
        </div>
    );
};

export default EULA;
