import React, { useEffect, useCallback, useContext, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { SearchNormal1 } from "iconsax-react";
import SearchContext from './SearchContext';
import { useTranslation } from 'react-i18next';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-100))',
        '&:hover': {
            backgroundColor: state.isFocused ? 'hsl(var(--nextui-default-100))' : 'hsl(var(--nextui-default-200))',
            borderColor: 'transparent',
        },
        borderColor: state.isFocused ? 'transparent' : 'transparent',
        borderRadius: '8px',
        boxShadow: state.isFocused ? 'transparent' : 'transparent',
        cursor: state.isFocused ? 'text' : 'pointer',
        display: 'flex',
        alignItems: 'center',
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-100))',
        color: 'hsl(var(--nextui-default-900))',
        borderRadius: '8px',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
            ? 'hsl(var(--nextui-default-200))'
            : 'hsl(var(--nextui-default-100))',
        color: 'hsl(var(--nextui-default-900))',
        fontSize: '0.875rem',
        cursor: 'pointer',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'hsl(var(--nextui-default-300))',
        borderRadius: '24px',
        paddingInlineStart: '8px',
        paddingBlock: '2px',
        color: 'hsl(var(--nextui-default-900))',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-800))',
        fontWeight: '500',
        fontFamily: 'Mona Sans',
        width: '100%',
        '@media (min-width: 200px)': {
            maxWidth: '100px',
        },
        '@media (min-width: 768px)': {
            maxWidth: '100px',
        },
        '@media (min-width: 1024px)': {
            maxWidth: '300px',
        },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
        paddingInline: '6px',
        marginInlineStart: '8px',
        ':hover': {
            backgroundColor: 'hsl(var(--nextui-default-900))',
            color: 'hsl(var(--nextui-default-200))',
        },
        borderRadius: '24px',
        cursor: 'pointer',
    }),
    input: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
        fontSize: '0.875rem',

    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '0.875rem',
        color: 'hsl(var(--nextui-default-500))',
        fontWeight: '500',
        fontFamily: 'Mona Sans',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
    clearIndicator: (provided) => ({
        ...provided,
        color: 'hsl(var(--nextui-default-900))',
        ':hover': {
            color: 'hsl(var(--nextui-default-900))',
        },
        cursor: 'pointer',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: "none",
    }),
};

const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
        <SearchNormal1 size={18} style={{ marginLeft: '14px', color: 'hsl(var(--nextui-default-500))' }} />
        {children}
    </components.Control>
);

const NoOptionsMessage = (props) => {
    const { inputValue } = props.selectProps;
    return inputValue ? (
        <components.NoOptionsMessage {...props}>
            No Options
        </components.NoOptionsMessage>
    ) : null;
};

function SearchBar({ allData, allUsers, setResults, setResultsCount, setSearching, extractMetadataValues, sortOrder }) {

    const [searchParams, setSearchParams] = useSearchParams();
    const { selectedOptions, setSelectedOptions, setGroupName } = useContext(SearchContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const tags = searchParams.get("tags");
        if (tags) {
            const selected = tags.split(",").map(tag => ({ value: decodeURIComponent(tag), label: decodeURIComponent(tag) }));
            setSelectedOptions(selected);
        }
    }, [searchParams]);

    const fetchOptions = useCallback((inputValue) => {
        const allOptions = new Set();

        allData.forEach((data) => {
            if (data.objects) {
                data.objects.forEach(obj => allOptions.add(JSON.stringify({ group: "objects", value: obj })));
            }
            if (data.metadata) {
                const metadataValues = extractMetadataValues(data.metadata);
                metadataValues.forEach(value => {
                    if (value.group !== "Location" && value.group !== "LocationName") {
                        allOptions.add(JSON.stringify(value));
                    }
                });
            }
        });

        allUsers.forEach((user) => {
            if (user.username) {
                allOptions.add(JSON.stringify({ group: "users", value: user.username }));
            }
        });

        const groupedOptions = Array.from(allOptions)
            .map(item => JSON.parse(item))
            .filter(item => item.value.toLowerCase().includes(inputValue.toLowerCase()))
            .reduce((acc, item) => {
                const group = acc.find(g => g.label === item.group);
                if (group) {
                    group.options.push({ value: item.value, label: item.value, group: item.group });
                } else {
                    acc.push({ label: item.group, options: [{ value: item.value, label: item.value, group: item.group }] });
                }
                return acc;
            }, []);

        return groupedOptions;
    }, [allData, allUsers, extractMetadataValues]);

    const loadOptions = async (inputValue, callback) => {
        const options = fetchOptions(inputValue);
        callback(options);
    };

    const handleChange = (selected) => {
        setSelectedOptions(selected || []);
        const tags = (selected || []).map(option => encodeURIComponent(option.value)).join(",");
        setSearchParams({ tags });

        const selectedOption = selected[0]; // Assuming single select, adjust if multi-select
        if (selectedOption && allUsers.some(user => user.username === selectedOption.value)) {
            navigate(`/profile/${selectedOption.value}`);
        } else {
            navigate(`/search?tags=${tags}`);
        }

        // Update the selected group
        if (selectedOption) {
            setGroupName(selectedOption.group);
        } else {
            setGroupName(null);
        }

      
    };

    useEffect(() => {
        const handleSearch = () => {
            if (selectedOptions.length === 0) {
                setResults([]);
                setResultsCount(0);
                setSearching(false);
                return;
            }

            setSearching(true);
            const filteredResults = allData.filter(data => {
                const metadataValues = data.metadata ? extractMetadataValues(data.metadata).map(item => `${item.value}`) : [];
                const objectValues = data.objects ? data.objects.map(obj => `${obj}`) : [];
                const allValues = [...metadataValues, ...objectValues];
                return selectedOptions.every(option =>
                    allValues.includes(`${option.value}`)
                );
            });

            const filteredUsers = allUsers.filter(user =>
                selectedOptions.some(option => option.value === user.username)
            );

            const sortedResults = [...filteredResults, ...filteredUsers].sort((a, b) => {
                const aTimestamp = a.timestamp ? a.timestamp.seconds : 0;
                const bTimestamp = b.timestamp ? b.timestamp.seconds : 0;
                return sortOrder === "mostRecent" ? bTimestamp - aTimestamp : aTimestamp - bTimestamp;
            });

            setResults(sortedResults);
            setResultsCount(sortedResults.length);
            setSearching(false);
        };

        handleSearch();
    }, [selectedOptions, allData, allUsers, extractMetadataValues, sortOrder]);

    return (
        <AsyncSelect
            isMulti
            cacheOptions
            loadOptions={loadOptions}
            onChange={handleChange}
            value={selectedOptions}
            placeholder={t('search')}
            formatGroupLabel={group => (
                <div>
                    <strong>{group.label}</strong>
                </div>
            )}
            styles={customStyles}
            components={{ Control: CustomControl, NoOptionsMessage }}
        />
    );
}

export default SearchBar;
