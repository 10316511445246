// DescriptionObjectCard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Skeleton, Link } from "@nextui-org/react";
import { ArchiveAdd, ArchiveMinus, Moon, SearchNormal1 } from "iconsax-react";
import { doc, collection, setDoc, getDoc } from 'firebase/firestore';
import { db } from './Firebase/firebaseConfig';
import MistralClient from '@mistralai/mistralai';

// Function to check if the object name contains a valid NGC object
const containsValidNGCObject = (objectName) => {
    const celestialPatterns = [
        { pattern: /NGC\s?\d+/i, type: 'NGC' },    // NGC objects
        { pattern: /IC\s?\d+/i, type: 'IC' },      // IC objects
        { pattern: /\b(mercury|venus|earth|mars|jupiter|saturn|uranus|neptune)\b/i, type: 'planet' }  // Planets
    ];

    for (let i = 0; i < celestialPatterns.length; i++) {
        if (celestialPatterns[i].pattern.test(objectName)) {
            return celestialPatterns[i];
        }
    }
    return null;
};

const DescriptionObjectCard = ({ activeTab, imageUrl, tagsParam, userId, isInTarget, onTargetToggle }) => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [localIsInTarget, setLocalIsInTarget] = useState(isInTarget);
    const [description, setDescription] = useState('');
    const [isCelestialObject, setIsCelestialObject] = useState(false); // State to store if it is a celestial object
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [safetyError, setSafetyError] = useState(false);
    const apiKey = 'qgoO1Njk0YkppIv6tZxLsssSQwwIdjVj';
    const client = new MistralClient(apiKey);
    const navigate = useNavigate();


    useEffect(() => {
        setLocalIsInTarget(isInTarget);
    }, [isInTarget]);

    const fetchDescription = async () => {
        try {
            setLoading(true);
            setError(false);
            setSafetyError(false);

            // Récupérer la langue du navigateur
            const browserLanguage = navigator.language || 'en'; // par défaut 'en' si la langue n'est pas définie



            const chatResponse = await client.chat({
                model: 'mistral-tiny',
                messages: [{
                    role: 'user',
                    content: `Please provide a detailed description of: ${tagsParam}, and avoid repeating the term ${tagsParam} in the result. (max 500 characters and in ${browserLanguage} language) `
                }],

            });

            console.log(chatResponse)

            const result = chatResponse.choices[0].message.content;

            setLoading(false);
            setDescription(result);
        } catch (error) {
            console.error('Error fetching description:', error);
            if (error.message.includes('Candidate was blocked due to SAFETY')) {
                setSafetyError(true);
            } else {
                setError(true);
            }
            setDescription('');
            setLoading(false);
        }
    };


    // Function to check if the tags parameter contains celestial object keywords
    const checkIfCelestialObject = () => {
        const celestialKeywords = [
            "NGC", "IC"
        ];

        const isCelestial = celestialKeywords.some(keyword => {
            if (keyword === "NGC" || "IC") {
                return tagsParam.includes(keyword);
            }
            return tagsParam.toLowerCase().includes(keyword.toLowerCase());
        });

        setIsCelestialObject(isCelestial);
        console.log(isCelestial)
    };


    useEffect(() => {
        if (activeTab !== 'target') {
            fetchDescription();
        }
        checkIfCelestialObject(tagsParam);
    }, [tagsParam, activeTab]);


    const saveMetadataToFirestore = async () => {
        if (!userId) {
            console.error('User is not authenticated.');
            alert('User is not authenticated.');
            return;
        }

        try {
            const userDocRef = doc(db, 'users', userId);
            const cosmioDocRef = doc(collection(userDocRef, 'cosmio'), 'targets');

            const targetsDoc = await getDoc(cosmioDocRef);
            const targetsData = targetsDoc.exists() ? targetsDoc.data() : {};

            const targets = targetsData.targets || [];

            if (localIsInTarget) {
                // Remove from targets
                const updatedTargets = targets.filter(item => item !== tagsParam);
                await setDoc(cosmioDocRef, { targets: updatedTargets }, { merge: true });
                setLocalIsInTarget(false);
                onTargetToggle(tagsParam, false);
            } else {
                // Add to targets
                targets.push(tagsParam);
                await setDoc(cosmioDocRef, { targets }, { merge: true });
                setLocalIsInTarget(true);
                onTargetToggle(tagsParam, true);
            }
        } catch (error) {
            console.error('Error saving metadata:', error);
        }
    };

    const isNGCObject = containsValidNGCObject(tagsParam);
    const stellariumUrl = isNGCObject ? `https://stellarium-web.org/skysource/${encodeURIComponent(tagsParam.match(isNGCObject.pattern)[0])}` : '#';

    return (
        <>
            {loading ? (
                <Skeleton className='h-16 rounded-xl w-full bg-default-50'></Skeleton>
            ) : (
                <div
                    className={`${activeTab !== 'target' ? 'hover:bg-default-100 cursor-pointer' : ''}  ${!imageUrl ? 'p-2' : ''} items-center w-full flex flex-row gap-2 text-default-600 rounded-xl text-sm font-medium bg-default-50  }`}
                    onClick={activeTab !== 'target' ? onOpen : null}
                >
                    {imageUrl ? (
                        <div className="flex-shrink-0 h-16 w-20">
                            <img
                                src={imageUrl}
                                alt="Description Image"
                                className="h-full w-full max-h-80 object-cover rounded-l-xl"
                            />
                        </div>
                    ) : (
                        <div className='p-2' >
                            {isCelestialObject ? (
                                <svg width="33" height="25" viewBox="0 0 33 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32.2953 12.3836C31.7137 10.6936 30.5698 9.22021 29.1628 8.13251C26.2903 5.98028 22.7123 5.25112 19.482 5.27328C17.8533 5.29242 16.2759 5.51499 14.8155 5.93496C13.342 6.34788 11.8997 7.07906 10.9271 8.29365C9.73171 9.7842 9.42632 11.6907 10.3606 13.3192C12.4248 16.9146 16.8998 15.3919 17.9349 14.3062C18.464 13.7523 18.4731 13.1873 18.207 13.008C17.7041 12.6142 16.1348 13.5035 14.6331 13.5962C14.6442 13.5649 14.0183 13.7321 13.1061 13.2306C12.7272 13.002 12.2545 12.5347 12.0771 11.8367C11.8745 11.1579 11.9632 10.3019 12.5447 9.55356C13.6705 7.98446 16.6398 7.21804 19.5294 7.06999C22.4563 6.95518 25.7349 7.55442 28.2587 9.34005C29.4954 10.2394 30.4891 11.4601 31.0102 12.8408C31.5394 14.2115 31.5343 15.7333 30.9749 17.0244C30.4297 18.3286 29.3865 19.3489 28.2758 20.0992C26.9101 21.0801 25.2622 21.6512 23.6607 22.0741C21.4091 22.6704 19.0869 22.8456 16.7688 22.8869C16.162 22.897 15.6551 23.046 15.6178 23.2333C15.5775 23.4338 16.0905 23.603 16.7678 23.612C17.57 23.6231 18.3723 23.6362 19.1736 23.5838C19.9295 23.5345 20.6864 23.4741 21.4393 23.3814C24.0125 23.0662 26.6965 22.4689 28.8816 21.0056C30.1283 20.213 31.3761 19.088 32.0907 17.524C32.8224 15.977 32.8909 14.0655 32.2953 12.3836Z" fill="white" />
                                    <path d="M22.1955 15.5247C23.3908 14.0269 23.6962 12.1102 22.7619 10.4748C20.6979 6.8609 16.2232 8.39104 15.1882 9.483C14.6601 10.0386 14.65 10.6073 14.9161 10.7875C15.419 11.1832 16.9892 10.2885 18.4908 10.1954C18.4797 10.2278 19.1056 10.0598 20.0176 10.5628C20.3956 10.7935 20.8692 11.2611 21.0466 11.9634C21.2492 12.6455 21.1605 13.5067 20.579 14.2576C19.4523 15.8343 16.4842 16.6045 13.5938 16.7532C10.6681 16.8696 7.38869 16.2675 4.86512 14.4722C3.62954 13.5685 2.63583 12.3419 2.11378 10.9555C1.58468 9.5761 1.59072 8.04696 2.14905 6.75059C2.69327 5.43802 3.73737 4.41387 4.84698 3.66094C5.01125 3.54254 5.19165 3.44842 5.36298 3.34115L5.34383 3.28751L4.10825 2.84729C2.90592 3.63767 1.72073 4.73468 1.03239 6.25066C0.300718 7.80408 0.231179 9.72588 0.826798 11.4149C1.40932 13.113 2.55319 14.5946 3.9601 15.6876C6.83238 17.8492 10.4101 18.5829 13.6402 18.5596C15.2698 18.5404 16.8471 18.3168 18.3064 17.8948C19.7808 17.4798 21.222 16.7441 22.1955 15.5247Z" fill="white" />
                                    <path d="M6.45941 3.56595C6.52147 3.74549 6.77539 3.74549 6.83746 3.56595L7.14208 2.68471C7.16177 2.62777 7.20608 2.58273 7.2627 2.56212L8.13577 2.24432C8.31123 2.18046 8.31123 1.93231 8.13577 1.86845L7.2627 1.55065C7.20608 1.53004 7.16177 1.48501 7.14208 1.42806L6.83746 0.546822C6.77539 0.367278 6.52147 0.367278 6.45941 0.546822L6.15478 1.42806C6.1351 1.48501 6.09079 1.53004 6.03417 1.55065L5.16109 1.86845C4.98564 1.93231 4.98564 2.18046 5.16109 2.24432L6.03417 2.56212C6.09079 2.58273 6.1351 2.62777 6.15478 2.68471L6.45941 3.56595Z" fill="white" />
                                    <path d="M10.7236 20.6781C10.6622 20.4974 10.4066 20.4973 10.345 20.678L9.95826 21.8127C9.93879 21.8698 9.89457 21.9151 9.83791 21.9359L8.72538 22.3446C8.55082 22.4087 8.5506 22.6555 8.72503 22.7199L9.83821 23.1311C9.89469 23.152 9.93876 23.1972 9.9582 23.2542L10.345 24.3883C10.4066 24.569 10.6621 24.5689 10.7236 24.3882L11.1096 23.2542C11.1291 23.1972 11.1731 23.152 11.2297 23.1311L12.3428 22.7199C12.5172 22.6555 12.517 22.4087 12.3424 22.3446L11.23 21.936C11.1733 21.9151 11.129 21.8698 11.1096 21.8126L10.7236 20.6781Z" fill="white" />
                                    <path d="M29.1858 4.7409C29.2486 4.9185 29.4998 4.9186 29.5628 4.74105L29.7704 4.1559C29.7906 4.09909 29.8353 4.0544 29.8921 4.03427L30.4771 3.82703C30.6547 3.76413 30.6548 3.51308 30.4773 3.45004L29.892 3.24222C29.8353 3.22206 29.7906 3.1774 29.7705 3.12064L29.5628 2.5354C29.4998 2.35785 29.2486 2.35795 29.1858 2.53554L28.9787 3.12049C28.9585 3.17734 28.9138 3.22207 28.857 3.24223L28.2714 3.45003C28.0938 3.51303 28.0939 3.76418 28.2715 3.82705L28.8569 4.03425C28.9138 4.05439 28.9586 4.09915 28.9787 4.15604L29.1858 4.7409Z" fill="white" />
                                </svg>

                            ) : (
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.0769 13.5816C19.9593 14.8446 19.5406 16.0609 18.8561 17.1287C18.1715 18.1966 17.241 19.0848 16.1424 19.719C15.0439 20.3532 13.8093 20.7148 12.5422 20.7737C11.275 20.8326 10.0122 20.5869 8.85957 20.0573C10.8708 19.2511 12.8198 18.2977 14.6907 17.2047C16.5727 16.1311 18.373 14.9201 20.0769 13.5816ZM21.3714 10.987C19.1367 12.9685 16.696 14.7049 14.0912 16.1664C11.5229 17.6913 8.79867 18.9366 5.96505 19.881C4.14092 20.443 2.77625 20.6002 1.84728 20.3513C1.59898 20.2956 1.3647 20.1897 1.15884 20.0401C0.952981 19.8905 0.779893 19.7004 0.650218 19.4815C-0.021761 18.3186 0.733993 16.7472 2.96161 14.6794C3.35436 14.3148 3.79101 13.941 4.25441 13.5634C4.11296 12.0504 4.40865 10.5286 5.10636 9.17859C5.80407 7.82861 6.87452 6.70717 8.19063 5.94739C9.50674 5.1876 11.0133 4.82138 12.5313 4.89219C14.0494 4.963 15.5153 5.46787 16.7551 6.34687C17.3138 6.13438 17.8559 5.94316 18.368 5.78535C21.2719 4.89075 23.0112 5.0207 23.6828 6.18487C24.3547 7.34841 23.5984 8.91895 21.3714 10.987ZM2.57145 18.3724C3.71204 18.3575 4.83975 18.1291 5.89627 17.6992C5.77517 17.5438 5.64921 17.3937 5.53842 17.2273C5.45242 17.0877 5.36641 16.9481 5.2804 16.8085C5.1027 16.4945 4.94642 16.1688 4.81262 15.8337C3.91366 16.5326 3.15351 17.3937 2.57145 18.3724ZM21.7617 7.29401C20.623 7.3087 19.4971 7.53642 18.4423 7.9654C18.6656 8.24881 18.8695 8.54697 19.0527 8.85785L19.2864 9.29067C19.3751 9.46981 19.4421 9.65396 19.5161 9.8365C20.4168 9.13661 21.1785 8.27425 21.7617 7.29401Z" fill="white" />
                                    <path d="M13.9196 8.96209C14.5515 8.96209 15.0637 8.44987 15.0637 7.81801C15.0637 7.18615 14.5515 6.67392 13.9196 6.67392C13.2877 6.67392 12.7755 7.18615 12.7755 7.81801C12.7755 8.44987 13.2877 8.96209 13.9196 8.96209Z" fill="black" />
                                </svg>

                            )}
                        </div>
                    )}

                    <div className="flex flex-row p-1 justify-between w-full ">
                        <div className="flex flex-row w-full items-center">
                            <div>
                                <p className="font-medium text-md ">{tagsParam}</p>
                                <p className='line-clamp-1 primary-gradient-text'>{description}</p>
                            </div>
                        </div>
                        <div className='flex flex-row gap' >

                            <Tooltip className='dark' content="View on Stellarium">
                                <Button variant='light' isIconOnly as="a" href={stellariumUrl} target="_blank" rel="noopener noreferrer">
                                    <Moon size={20} className='origin-center -rotate-90' />
                                </Button>
                            </Tooltip>

                            {activeTab === 'target' ? (
                                <Tooltip className='dark' content="Search">
                                    <Button isIconOnly variant='light' onPress={() => navigate(`/search?tags=${encodeURIComponent(tagsParam)}`)}>
                                        <SearchNormal1 size={20} />
                                    </Button>
                                </Tooltip>
                            ) : null}

                            <Tooltip className='dark' content={localIsInTarget ? "Remove from target" : "Add to target"}>
                                <Button isIconOnly variant='light' onClick={(e) => { e.stopPropagation(); saveMetadataToFirestore(); }}>
                                    {localIsInTarget ? <ArchiveMinus size={20} variant='Bold' /> : <ArchiveAdd />}
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>

            )}
            <Modal className='purple-dark' scrollBehavior='inside' placement='center' isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{tagsParam}</ModalHeader>
                            <ModalBody>
                                {imageUrl && (
                                    <img
                                        src={imageUrl}
                                        alt="Description Image"
                                        className="h-full w-full max-h-80 object-cover rounded-md"
                                    />
                                )}
                                <div className='flex flex-row gap-2' >
                                    <Tooltip className='dark' content={localIsInTarget ? "Remove from target" : "Add to target"}>
                                        <Button size='sm' onClick={(e) => { e.stopPropagation(); saveMetadataToFirestore(); }}>
                                            {localIsInTarget ? <><ArchiveMinus size={18} variant='Bold' /> Remove from target</> : <><ArchiveAdd size={18} /> Add to target</>}
                                        </Button>
                                    </Tooltip>

                                    {isNGCObject ? (
                                        <Button size='sm' as="a" href={stellariumUrl} target="_blank" rel="noopener noreferrer">
                                            <Moon className='origin-center -rotate-90' size={18} />
                                            View on Stellarium
                                        </Button>
                                    ) : (
                                        <p>Object not available in Stellarium</p>
                                    )}
                                </div>
                                <p>{description}</p>
                            </ModalBody>
                            <ModalFooter>
                                {/* Footer content if any */}
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default DescriptionObjectCard;
