import React, { useEffect, useState } from "react";
import { Table, TableColumn, TableHeader, TableBody, TableRow, TableCell } from "@nextui-org/react";
import { collection, onSnapshot, query, where, getDocs } from "firebase/firestore";
import { db } from "./Firebase/firebaseConfig";

function Ranking() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const CACHE_KEY = 'users_cache';
        const CACHE_TIMESTAMP_KEY = 'users_cache_timestamp';
        const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

        const loadFromCache = () => {
            const cachedData = localStorage.getItem(CACHE_KEY);
            const cachedTimestamp = localStorage.getItem(CACHE_TIMESTAMP_KEY);

            if (cachedData && cachedTimestamp) {
                const currentTime = new Date().getTime();
                const cacheAge = currentTime - parseInt(cachedTimestamp, 10);

                if (cacheAge < CACHE_DURATION) {
                    setUsers(JSON.parse(cachedData));
                    return true;
                }
            }
            return false;
        };

        const saveToCache = (data) => {
            localStorage.setItem(CACHE_KEY, JSON.stringify(data));
            localStorage.setItem(CACHE_TIMESTAMP_KEY, new Date().getTime().toString());
        };

        const fetchUsers = async () => {
            const usersData = [];
            const querySnapshot = await getDocs(collection(db, "users"));
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.username) { // vérifier l'existence de la propriété "username"
                    const userData = { ...data, key: doc.id };
                    usersData.push(userData);
                }
            });

            const fetchFollowers = async (user) => {
                const subscriptionsRef = collection(db, "subscriptions");
                const q = query(subscriptionsRef, where("subscribedToId", "==", user.key));
                const querySnapshot = await getDocs(q);
                user.followerCount = querySnapshot.size;
            };

            await Promise.all(usersData.map(fetchFollowers));

            usersData.sort((a, b) => b.followerCount - a.followerCount);

            // Limit the displayed users to a maximum of 20
            const limitedUsers = usersData.slice(0, 20);

            setUsers(limitedUsers);
            saveToCache(limitedUsers);
        };

        const initialize = async () => {
            if (!loadFromCache()) {
                await fetchUsers();
            }
        };

        let unsubscribe = onSnapshot(collection(db, "users"), () => {
            fetchUsers();
        });

        initialize();

        return () => unsubscribe();
    }, []);

    const columns = [
        {
            key: "username",
            label: "USERNAME",
        },
        {
            key: "followerCount",
            label: "FOLLOWERS",
        },
    ];

    const renderCell = (user, columnKey) => {
        const cellValue = user[columnKey];
        switch (columnKey) {
            case "username":
                return (
                    <TableCell>
                        <div className="flex items-center space-x-2">
                            {cellValue}
                        </div>
                    </TableCell>
                );
            case "followerCount":
                return <TableCell>{cellValue}</TableCell>;
            default:
                return <TableCell>{cellValue}</TableCell>;
        }
    };

    return (
        <div>
            <h1 className="text-3xl font-bold mb-4">Ranking</h1>
            <Table isStriped className="dark" aria-label="Users table">
                <TableHeader columns={columns}>
                    {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                </TableHeader>
                <TableBody items={users}>
                    {(user) => (
                        <TableRow key={user.key}>
                            {(columnKey) => renderCell(user, columnKey)}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

export default Ranking;
