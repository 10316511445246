import React, { useEffect, useState, useContext, useCallback } from "react";
import { collection, getDocs, query, where, orderBy, limit } from "firebase/firestore";
import { db } from "../components/Firebase/firebaseConfig";
import UserContext from "../components/UserContext";
import ImageCard from './ImageCard';
import { Link } from "react-router-dom";
import { Profile2User } from "iconsax-react";

function FollowedFeed() {
    const [images, setImages] = useState([]);
    const { currentUser } = useContext(UserContext);
    const [followedUserIds, setFollowedUserIds] = useState([]); // Ajout de l'état pour les ID des utilisateurs suivis
    const IMAGE_LIMIT = 20; // Variable to control the number of images fetched

    const fetchFollowedUsersImages = useCallback(async () => {
        if (!currentUser) return;



        try {
            // Get the list of users the current user is following
            const followsCollection = collection(db, "subscriptions");
            const followsSnapshot = await getDocs(query(followsCollection, where("subscriberId", "==", currentUser.uid)));
            const followedUserIds = followsSnapshot.docs.map(doc => doc.data().subscribedToId);

            setFollowedUserIds(followedUserIds); // Mettre à jour l'état des ID des utilisateurs suivis

            if (followedUserIds.length === 0) return;

            // Ensure that followedUserIds array is not undefined and contains valid user IDs
            if (!followedUserIds || followedUserIds.length === 0) {
                console.warn("No followed users found or invalid followed user IDs");
                return;
            }

            // Get the images of the followed users, limit to IMAGE_LIMIT
            const imagesCollection = collection(db, "images");
            const imagesSnapshot = await getDocs(query(
                imagesCollection,
                where("author", "in", followedUserIds),
                where("state", "==", true),
                where("publish", "==", true),
                orderBy("timestamp", "desc"), // Order by timestamp in descending order
                limit(IMAGE_LIMIT) // Limit the query to IMAGE_LIMIT documents
            ));
            const imagesList = imagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            setImages(imagesList);
        } catch (error) {
            console.error("Error fetching followed users' images:", error);
        }
    }, [currentUser, IMAGE_LIMIT]);

    useEffect(() => {
        fetchFollowedUsersImages();
    }, [fetchFollowedUsersImages]);

    return (
        <div className=" ">
         
            {followedUserIds.length === 0 && (

                <div className="flex flex-col items-center justify-center mt-6  gap-4 ">
                    <Profile2User size="70"  variant="Linear" color="hsl(var(--nextui-default-400))" />
                    <p className="text-md text-default-500 text-center">
                        You are not following anyone yet. <Link to="/search" className="underline">Find users to follow!</Link>
                    </p>
                </div>
            )}
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                {images.map((image) => (
                    <ImageCard key={image.id} image={image} />
                ))}
            </div>
        </div>
    );
}

export default FollowedFeed;
