// PerspectiveService.js
import axios from 'axios';

const PERSPECTIVE_API_KEY = 'AIzaSyARmd6-70E4YD8iPCWrYctvrMidnu-6ypM'; // Remplacez par votre clé API Perspective

const LANGUAGES = ['en', 'fr', 'es', 'de', 'it', 'pt', 'ru', 'ja', 'zh', 'ko'];

export const checkProfanity = async (text) => {
    try {
        const response = await axios.post(`https://commentanalyzer.googleapis.com/v1alpha1/comments:analyze?key=${PERSPECTIVE_API_KEY}`, {
            comment: { text },
            languages: LANGUAGES,
            requestedAttributes: { TOXICITY: {}, INSULT: {}, PROFANITY: {} }
        });
        const scores = response.data.attributeScores;
        const toxicity = scores.TOXICITY.summaryScore.value;
        const insult = scores.INSULT.summaryScore.value;
        const profanity = scores.PROFANITY.summaryScore.value;

        if (toxicity > 0.8 || insult > 0.8 || profanity > 0.8) {
            return true;
        }
        return false;
    } catch (error) {
        console.error('Error checking text with Perspective API', error);
        return false;
    }
};
